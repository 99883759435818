import React from "react";
import { Routes as DomRoutes, Route, Navigate } from "react-router-dom";
import {
  Home,
  Company,
  Alliances,
  Materials,
  FAQs,
  Contact,
  Budget,
  Step2,
  Step3,
  Step4,
  Results,
  Presupuesto,
  Requisitos,
  RequisitosId,
} from "../pages";
import FAQsId from "../pages/faqs/FAQsId";
import { PresupuestoId } from "../pages/presupuesto/PresupuestoId";

const Routes = () => {
  return (
    <DomRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/la-compania" element={<Company />} />
      <Route path="/alianzas" element={<Alliances />} />
      <Route path="/materiales" element={<Materials />} />
      <Route path="/preguntas-frecuentes">
        <Route index element={<FAQs />} />
        <Route path="/preguntas-frecuentes/:faq" element={<FAQsId />} />
      </Route>
      <Route path="/contacto" element={<Contact />} />
      <Route path="/requisitos"> 
        <Route path="/requisitos" element={<Requisitos />} />
        <Route path="/requisitos/:id" element={<RequisitosId />} />
      </Route>
      <Route path="/presupuesto" element={<Presupuesto />} />
      <Route path="/presupuesto/:id">
        <Route path="/presupuesto/:id" element={<PresupuestoId />} />
        <Route path="/presupuesto/:id/contacto" element={<Budget />} />
        <Route
          path="/presupuesto/:id/detalles-de-la-mudanza"
          element={<Step2 />}
        />
        <Route path="/presupuesto/:id/volumenes" element={<Step3 />} />
        <Route path="/presupuesto/:id/calcula-tu-volumen" element={<Step4 />} />
        <Route path="/presupuesto/:id/vista-previa" element={<Results />} />
      </Route>
      <Route path="/*" element={<Navigate replace={true} to="/" />}>
        {" "}
      </Route>
    </DomRoutes>
  );
};

export default Routes;
