import React from "react";
import styled from "styled-components";
import { Button, Paragraph, Secondarytitle, Title } from "../../../components";

const ServicesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
  width: 100%;
  background-color: var(--offWhite);
  gap: 10px;
`;

const CardsContainer = styled.div`
  max-width: 1400px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin: 0 auto;
  margin: 15px 0px;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 320px;
  background-color: var(--pearlGrey);
  gap: 5px;
  padding: 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const CardIcon = styled.img`
  // margin as prop
  margin-left: ${(props) => (props.ml ? props.ml : "0")};
  height: 70px;
`;

const TextSpan = styled.span`
  /* font-family: ; */
  font-weight: bold;
  font-style: italic;
`

const CardTitleContainer = styled.div``;

export const Services = () => {
  return (
    <ServicesContainer>
      <Title color="var(--marineBlue)" family="Manrope-bold" size="30px">
        Nuestro servicio personalizable
      </Title>

      <Paragraph
        size="14px"
        align="center"
        color="var(--orange)"
        family="Manrope-regular"
      >
        ¿Cómo podemos ayudarte?
      </Paragraph>
      <CardsContainer>
        <Card>
          <CardTitleContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              ¿Tus muebles? Nuestra responsabilidad.
            </Secondarytitle>
            <CardIcon
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673206382/iconos/iconos_Mesa_de_trabajo_1_tdcye7.png"
              ml="-12px"
            />
          </CardTitleContainer>
          <Paragraph
            size="12px"
            align="center"
            color="var(--marineBlue)"
            family="Manrope-regular"
          >
            Elishar te ofrece la <TextSpan>tranquilidad</TextSpan> de
            que tus pertenencias van a estar en
            <TextSpan>buenas manos</TextSpan>. Este proceso
            comienza con el embalaje, realizado
            con nuestro propio material y
            nuestros embaladores
            profesionales. Tu carga será
            transportada a nuestro <TextSpan>almacén</TextSpan> en
            nuestros <TextSpan>camiones</TextSpan> y estará
            almacenada con nosotros hasta la
            fecha de embarque.
          </Paragraph>
        </Card>
        <Card>
          <CardTitleContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              ¿Transporte? ¿Trámites? Nosotros nos encargamos.
            </Secondarytitle>
            <CardIcon
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673206383/iconos/iconos-02_hxf69p.png"
              ml="-5px"
            />
          </CardTitleContainer>
          <Paragraph
            size="12px"
            align="center"
            color="var(--marineBlue)"
            family="Manrope-regular"
          >
            ¡Todos a bordo!
          </Paragraph>
          <Paragraph
            size="12px"
            align="center"
            color="var(--marineBlue)"
            family="Manrope-regular"
          >
            Luego de almacenar tu carga
            realizamos la reserva a la <TextSpan>compañía
            naviera, aérea o terrestre</TextSpan> que
            llevará tus cosas al país de destino.
            Al día del embarque nosotros
            hacemos los trámites aduaneros, y
            de vos solo necesitamos que nos
            entregues la documentación
            pertinente.
          </Paragraph>
        </Card>
        <Card>
          <CardTitleContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              ¿Ya llegamos?
            </Secondarytitle>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              ¡Sí!
            </Secondarytitle>
            <CardIcon
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673206382/iconos/iconos-03_m48wpt.png"
              ml="-3px"
            />
          </CardTitleContainer>
          <Paragraph
            size="12px"
            align="center"
            color="var(--marineBlue)"
            family="Manrope-regular"
          >
            Ahora solo queda que te <TextSpan>relajes</TextSpan> y
            veas <TextSpan>tus cosas llegar</TextSpan> a su destino
            final.
          </Paragraph>
          <Paragraph
            size="12px"
            align="center"
            color="var(--marineBlue)"
            family="Manrope-regular"
          >
            Al recibir la carga nosotros nos
            encargamos del desaduanado y
            llevamos tus cosas a tu casa, en
            donde las desembalamos.
          </Paragraph>
        </Card>
      </CardsContainer>
      <Paragraph
        size="14px"
        align="center"
        color="var(--orange)"
        family="Manrope-regular"
      >
        ¿Querés saber más?
      </Paragraph>
      <Button
        to="/preguntas-frecuentes"
        mt="15px"
        size="14px"
        background="var(--marineBlue)"
        color="var(--offWhite)"
        family="Manrope-semibold"
        width="280px"
        hoverbackground="transparent"
        hovercolor="var(--marineBlue)"
        border="1px solid var(--marineBlue)"
      >
        LEE LAS PREGUNTAS FRECUENTES
      </Button>
    </ServicesContainer>
  );
};
