import { initializeApp } from "firebase/app";
import { collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
// import { API_KEY,
//     AUTH_DOMAIN, 
//     PROJECT_ID, 
//     STORAGE_BUCKET, 
//     MESSAGING_SENDER_ID, 
//     APP_ID,
//     MEASUREMENT_ID
// } from "./config.env";

const firebaseConfig = {
  apiKey: "AIzaSyAqe-xSe4btCZs8h-uBzwp1xlfkdWjmWgA",
  authDomain: "elisharweb-d39e7.firebaseapp.com",
  projectId: "elisharweb-d39e7",
  storageBucket: "elisharweb-d39e7.appspot.com",
  messagingSenderId: "938263440437",
  appId: "1:938263440437:web:74d9b81aed8a24ef77d57c",
  measurementId: "G-0DQVYV2HXB",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const docRef = collection(db, "leads"); 

const docum = doc(docRef, "leads");

export const getLeads = async () => {
  const leads = await getDoc(docum);
  return leads.data();
}

export const updateLeadsIds = async (document) => {
  await updateDoc(docum, document);
}

export default app;