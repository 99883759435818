import { createSlice } from "@reduxjs/toolkit";

const accordionSlice = createSlice({
  name: "accordion",
  initialState: {
    accordionIndex: -1,
  },
  reducers: {
    toggleAccordion: (state, payload) => {
      state.accordionIndex = payload;
    },
  },
});

export const accordionReducer = accordionSlice.reducer;

export const { toggleAccordion } = accordionSlice.actions;
