import styled from 'styled-components'
import { mediaQueries } from '../../config'
import { useNavigate } from 'react-router-dom'


const FaqsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 75%;
  padding: 12% 0px;
  margin: 0 auto;
`

const FaqsTitle = styled.h2`
  font-family: 'Manrope-bold';
  font-size: 2.5rem;
  color: var(--marineBlue);

  @media (max-width: 570px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
`

const FaqsSubtitle = styled.h3`
  font-family: 'Manrope-extraLight';
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--marineBlue);
  letter-spacing: 1px;
  text-align: center;

  @media (max-width: 570px) {
    font-size: 1.3rem;
  }
  @media (max-width: 450px) {
    font-size: 1.1rem;
  }
`

const FaqsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  /* padding-bottom: 10%; */
`

const FaqsButton = styled.button`
  font-family: 'Manrope-regular';
  background-color: ${props => props.selected ? 'var(--orange)' : 'var(--offWhite)'};
  color: ${props => props.selected ? 'var(--offWhite)' : 'var(--orange)'};
  font-size: 2.3rem;
  border: none;
  border-radius: 5px;
  padding: 25px 120px;
  
  ${mediaQueries.desktop} {
    font-size: 2rem;
    padding: 25px 100px;
  }

  @media (max-width: 570px) {
    font-size: 1.5rem;
    padding: 0px;
    width: 320px;
    height: 70px;
  }
  @media (max-width: 400px) {
    font-size: 1.3rem;
    width: 280px;
    height: 60px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--orange);
    color: var(--offWhite);
    transition: all 0.3s ease-in-out;
  }

`


export const FAQs = () => {
  const navigate = useNavigate();

  return (
    <>
      <FaqsContainer>
        <FaqsTitle>Preguntas frecuentes</FaqsTitle>
        <FaqsSubtitle>¿Tenés alguna duda? Revisá nuestras preguntas frecuentes para más información.</FaqsSubtitle>
        <FaqsButtonContainer>
          <FaqsButton 
            onClick={() => navigate('/preguntas-frecuentes/hacia-argentina')}
          >
            Hacia Argentina
          </FaqsButton>
          <FaqsButton 
            onClick={() => navigate('/preguntas-frecuentes/desde-argentina')}
          >
            Desde Argentina
          </FaqsButton>
        </FaqsButtonContainer>
      </FaqsContainer>
    </>
  )
}
