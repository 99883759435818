import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { chooseStep } from '../../store/steperSlice/SteperSlice'


const SteperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`

const SteperItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.w)};
  font-family: 'Manrope-semiBold';
  color: ${(props) => (props.active ? "var(--marineBlue)" : "var(--pearlGrey)")};
  padding: 20px 0px;
  cursor: pointer;
  user-select: none;
  pointer-events: ${(props) => (props.disable ? "none" : "auto")};
  transition: all 0.5s ease-in-out;
  border-bottom: ${(props) => (props.active ? "2px solid var(--marineBlue)" : "2px solid var(--pearlGrey)")};
  @media (max-width: 530px) {
    font-size: 14px;
  }
  @media (max-width: 460px) {
    font-size: 10px;
  }
`

export const Steper = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const step = useSelector((state) => state.steps.step);

  const handleContacto = () => {
    dispatch(chooseStep('contact'));
    navigate(`/presupuesto/${id}/contacto`);
  }
  const handleDetails = () => {
    dispatch(chooseStep('details'));
    navigate(`/presupuesto/${id}/detalles-de-la-mudanza`);
  }
  const handleVolumes = () => {
    dispatch(chooseStep('volume'));
    navigate(`/presupuesto/${id}/volumenes`);
  }
  

  return (
    <>
      <SteperContainer>
        <SteperItem w="29%" active={step === 'contact'} onClick={handleContacto}> 01 Contacto </SteperItem>
        <SteperItem disable={state.root.contact.name === ''} w="42%" active={step === 'details'} onClick={handleDetails}> 02 Detalles de la mudanza </SteperItem>
        <SteperItem disable={state.root.contact.name === '' || state.root.details.from.country === ''} w="29%" active={step === 'volume'} onClick={handleVolumes}> 03 Volumen </SteperItem>
      </SteperContainer>
    </>
  )
}