import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleAccordion } from "../../store/accordionSlice/accordionSlice";
import { toggleMenu } from "../../store/menuSlice/menuSlice";

const HamburgerContainer = styled.div`
  display: none;
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 40px;
  right: 0px;
  z-index: 999;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  @media (max-width: 992px) {
    display: flex;
    margin-right: 50px;
  }
  @media (max-width: 576px) {
    margin-right: 20px;
  }
`;

const Icon = styled.span`
  position: relative;
  background-color: ${(props) =>
    props.clicked === true ? "transparent" : props.color};
  width: 75%;
  height: 1.3px;
  display: inline-block;
  transition: all 0.3s;
  &::before,
  &::after {
    background-color: ${(props) => props.color};
    content: "";
    width: 100%;
    height: 1.3px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  &::before {
    top: ${(props) => (props.clicked === true ? "0" : "-0.5rem")};
    transform: ${(props) =>
      props.clicked === true ? "rotate(135deg)" : "rotate(0)"};
  }
  &::after {
    top: ${(props) => (props.clicked === true ? "0" : "0.5rem")};
    transform: ${(props) =>
      props.clicked === true ? "rotate(-135deg)" : "rotate(0)"};
  }
`;

export const BarsMenu = (props) => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector((state) => state.menu);

  const onClickHandler = () => {
    dispatch(toggleMenu());
    dispatch(toggleAccordion(-1));
  };

  return (
    <HamburgerContainer onClick={onClickHandler} clicked={isMenuOpen}>
      <Icon clicked={isMenuOpen} color={props.color}></Icon>
    </HamburgerContainer>
  );
};
