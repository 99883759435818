import { createGlobalStyle } from "styled-components";
import manropeRegular from "../assets/fonts/Manrope-Regular.ttf";
import manropeBold from "../assets/fonts/Manrope-Bold.ttf";
import manropeExtraBold from "../assets/fonts/Manrope-ExtraBold.ttf";
import manropeLight from "../assets/fonts/Manrope-Light.ttf";
import manropeMedium from "../assets/fonts/Manrope-Medium.ttf";
import manropeSemiBold from "../assets/fonts/Manrope-SemiBold.ttf";
import manropeVariable from "../assets/fonts/Manrope-VariableFont_wght.ttf";
import manropeExtraLiht from "../assets/fonts/Manrope-ExtraLight.ttf";

export const GlobalStyles = createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  *::-webkit-scrollbar{
    width: 5px;
  }
  *::-webkit-scrollbar-track{
    background: inherit;
  }
  *::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background: var(--marineBlue);
  }
  *:focus {
    outline: none;
  }
  *:focus-visible {
    outline: none;
  }


  @font-face {
    font-family: 'Manrope-regular';
    font-style: normal;
    font-weight: normal;
    src: url(${manropeRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-bold';
    font-style: normal;
    font-weight: 600;
    src: url(${manropeBold}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-extraBold';
    font-style: normal;
    font-weight: 700;
    src: url(${manropeExtraBold}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-light';
    font-style: normal;
    font-weight: 300;
    src: url(${manropeLight}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-medium';
    font-style: normal;
    font-weight: 400;
    src: url(${manropeMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-semiBold';
    font-style: normal;
    font-weight: 500;
    src: url(${manropeSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-extraLight';
    font-style: normal;
    font-weight: 200;
    src: url(${manropeVariable}) format('truetype');
  }
  @font-face {
    font-family: 'Manrope-variable';
    font-style: normal;
    src: url(${manropeExtraLiht}) format('truetype');
  }
  :root{
    --marineBlue: #223355;
    --grafGrey: #333333;
    --offWhite: #EEEEEE;
    --pearlGrey: #E0E0E0;
    --orange: #EE8833;
    --footerGrey: #929292;
  }
  html{
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  input[type="date"] {
    color: #929292;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.5;
    padding-right: 20px;
  }

/* 
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  } */



  //Create a display and then opacity animation

  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
      
    }
    
  }

  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
      
    }

  }
`;
