import { configureStore } from "@reduxjs/toolkit";
import { menuReducer } from "./menuSlice/menuSlice";
import { rootReducer } from "./rootSlice/rootSlice";
import { steperReducer } from "./steperSlice/SteperSlice";
import { volumeReducer } from "./volumeSlice/VolumeSlice";
import { accordionReducer } from "./accordionSlice/accordionSlice";

export const store = configureStore({
  reducer: {
    root: rootReducer,
    menu: menuReducer,
    steps: steperReducer,
    volume: volumeReducer,
    accordion: accordionReducer,
  },
});
