import React from "react";
import WhatsappBTN from "../../components/whatsAppBTN/WhatsappBTN";

import { Banner, Hero, Alliances } from "./sections";
import { Services } from "./sections";

export const Home = () => {
  return (
    <>
      <Hero />
      <WhatsappBTN />
      <Services />
      <Banner />
      <Alliances />
    </>
  );
};
