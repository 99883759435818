import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Paragraph, Title, Image } from "../../components";
import { SingleSegment } from "../alliances/Alliances";
import { MaterialTextContainer } from "../materials/Materials";

const SectionContainer = styled.div`
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  align-items: center;
  gap: 40px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  @media (max-width: 992px) {
    justify-content: center;
    width: 90%;
  }
`;

const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: -135px;
  @media (max-width: 992px) {
    margin-left: 0px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 100px;
  @media (max-width: 992px) {
    flex-direction: column;
    gap: 50px;
    align-items: center;
    width: min(90%, 500px);
  }
`;
const InfoImageContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media (max-width: 992px) {
    align-items: center;
  }
`;
const InfoImage = styled.img`
  height: 350px;
  max-width: 300px;
`;

const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 300px;
  justify-content: center;
  gap: 20px;
  color: var(--marineBlue);
  @media (max-width: 992px) {
    flex-direction: column;
    gap: unset;
    justify-content: flex-start;
    height: unset;
    width: 100%;
  }
`;

const InfoItem = styled.li`
  font-size: 13px;
  font-weight: 400;
  font-size: 13px;
  font-family: "Manrope-regular";
`;

const InfoSpan = styled.span`
  font-family: "Manrope-regular";
  font-weight: 600;
  color: var(--marineBlue);
  font-size: 13px;
  width: 350px;
  @media (max-width: 576px) {
    width: 300px;
  }
`;

const DocumentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 805px) {
    gap: 30px;
    width: 100%;
  }
`;

const SegmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 70px 0px;
  @media (max-width: 805px) {
    align-items: center;
    padding: 40px 0px;
  }
`;
const DocumentationSegment = styled(SingleSegment)`
  border-bottom: 0px solid var(--pearlGrey);
  padding: 0px;
  gap: 150px;
  @media (max-width: 805px) {
    align-items: center;
    gap: 30px;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 45px;
  margin-left: 40px;
  @media (max-width: 992px) {
    justify-content: center;
    gap: 30px;
    margin-left: unset;
  }
`;

const StepsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 35px 15px;
  max-width: 230px;
  background-color: var(--pearlGrey);
  font-family: "Manrope-regular";
  font-size: 13px;
  border-radius: 15px;
  color: var(--marineBlue);
`;

export const RequisitosId = () => {
  const { id } = useParams();

  return (
    <>
      <SectionContainer>
        {id === "importacion" && (
          <>
            <SectionTitleContainer>
              <Title color="var(--marineBlue)" size="26px">
                Requisitos para la importación de efectos personales
              </Title>
              <Paragraph color="var(--marineBlue)" size="16px" weight="600">
                Argentinos retornando y residentes permanentes.
              </Paragraph>
            </SectionTitleContainer>
            <InfoContainer>
              <InfoImageContainer>
                <InfoImage src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059728/requisitos/fotos-02_layqri.jpg" />
                <InfoSpan>
                  *En caso de no cumplir con estos requisitos consultar a su
                  agente de mudanza de Elishar.
                </InfoSpan>
              </InfoImageContainer>
              <InfoList>
                <InfoItem>
                  Haber estado al menos 1 año fuera de la Argentina, y
                  comprobarlo mediante un Certificado de Residencia.
                </InfoItem>
                <InfoItem>
                  No haber estado mas de 60 días en la Argentina en los 365 días
                  anteriores al retorno definitivo.
                </InfoItem>
                <InfoItem>
                  No haber estado mas de 180 días en Argentina luego del retorno
                  definitivo.
                </InfoItem>
                <InfoItem>
                  No haber realizado una importación de efectos personales a la
                  Argentina en los últimos 7 años.
                </InfoItem>
              </InfoList>
            </InfoContainer>

            <DocumentationContainer>
              <Title color="var(--marineBlue)" mt="30px" size="26px">
                Documentación
              </Title>
              <SegmentsContainer>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Fotocopia del pasaporte con el que entró a la Argentina
                      (todas las hojas) <br />
                      Autenticada por Escribano y Colegio de Escribanos.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059773/iconos%20requisitos/ICONOS_Mesa_de_trabajo_1_eop87t.png"
                    alt="Pasaporte"
                    height="300px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Certificado de Residencia emitida por el Consulado
                      Argentino en el país de Origen.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-09_kj43ua.png"
                    alt="Certificado de Residencia"
                    height="230px"
                  />
                </DocumentationSegment>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      minWidth="330px"
                      ml="30px"
                    >
                      Constancia de CUIT/CUIL
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-05_px4p7t.png"
                    alt="Constancia de Cuit/Cuil"
                    height="200px"
                  />
                </DocumentationSegment>

                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Fotocopia del DNI (frente y dorso) con cambio de domicilio
                      actualizado (Luego del último ingreso al país)
                      <br></br>
                      <br></br>
                      De no contar con DNI actualizado, AGREGAR certificado de
                      domicilio o constancia de DNI en trámite.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059773/iconos%20requisitos/ICONOS-03_nhqqzz.png"
                    alt="Fotocopia del DNI"
                    height="170px"
                  />
                </DocumentationSegment>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Movimientos migratorios del año en curso y el anterior.{" "}
                      <br />
                      Este trámite se realiza AQUÍ o mediante nuestro gestor.
                      (U$D 100)
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-04_nkafbo.png"
                    alt="Globo terraqueo"
                    height="230px"
                  />
                </DocumentationSegment>

                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Autorización para el despacho de Aduana.
                      <br></br>
                      Firma certificada por Escribano Público y Colegio de
                      Escribanos.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-06_byosqw.png"
                    alt="Checklist"
                    height="200px"
                  />
                </DocumentationSegment>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Carta de garantía firmada con firma certificada por
                      Escribano Público.
                      <br />
                      Sólo en caso de ingresar los bienes con un contenedor
                      exclusivo.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059775/iconos%20requisitos/ICONOS-12_yxax7j.png"
                    alt="Documentos"
                    height="230px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Entregar toda la documentación MÁXIMO 3 días hábiles antes
                      de la llegada del buque.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059775/iconos%20requisitos/ICONOS-13_z0lyon.png"
                    alt="Calendario"
                    height="200px"
                  />
                </DocumentationSegment>
              </SegmentsContainer>
            </DocumentationContainer>
          </>
        )}
        {id === "exportacion" && (
          <>
            <SectionTitleContainer>
              <Title color="var(--marineBlue)" size="26px">
                Requisitos para la exportación de efectos personales
              </Title>
              <Paragraph color="var(--marineBlue)" size="16px" weight="600">
                Bajo el régimen de “Equipaje no acompañado”
              </Paragraph>
            </SectionTitleContainer>
            <InfoContainer>
              <InfoImageContainer>
                <InfoImage src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059726/requisitos/fotos_Mesa_de_trabajo_1_fjtjcv.jpg" />
                <InfoSpan>
                  *En caso de no cumplir con estos requisitos consultar a su
                  agente de mudanza de Elishar.
                </InfoSpan>
              </InfoImageContainer>
              <InfoList>
                <InfoItem>
                  Tener residencia en Argentina (mínimo un año).
                </InfoItem>
                <InfoItem>
                  No haber permanecido mas de 60 días (en total) fuera del pais
                  los último 365 días (previo al inicio del tramite de la
                  mudanza).
                </InfoItem>
                <InfoItem>
                  Contar con un permiso de residencia permanente en el pais de
                  destino.
                </InfoItem>
              </InfoList>
            </InfoContainer>
            <DocumentationContainer>
              <Title color="var(--marineBlue)" mt="30px" size="26px">
                Documentación
              </Title>
              <StepsContainer>
                <StepsBox>
                  Fotocopia autenticada por escribano público.
                  <Image
                    position="absolute"
                    bottom="-10px"
                    right="-10px"
                    height={"40px"}
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059775/iconos%20requisitos/numeros_Mesa_de_trabajo_1_ue1wbo.png"
                  ></Image>
                </StepsBox>
                <StepsBox>
                  Firma certificada por escribano público y legalizada por
                  Colegio de Escribanos.
                  <Image
                    position="absolute"
                    bottom="-10px"
                    right="-10px"
                    height={"40px"}
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059775/iconos%20requisitos/numeros-02_eyja6m.png"
                  ></Image>
                </StepsBox>
                <StepsBox>
                  Trámite personal realizado en página web <br />
                  "TRAMITES A DISTANCIA".
                  <Image
                    position="absolute"
                    bottom="-9px"
                    right="-10px"
                    height={"40px"}
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059775/iconos%20requisitos/numeros-03_tsrncl.png"
                  ></Image>
                </StepsBox>
              </StepsContainer>
              <SegmentsContainer>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Fotocopia del pasaporte Argentino y del país de destino
                      (todas las hojas) Debe acreditar residencia definitiva en
                      el país al que va. <br />
                      Si te mudas a un país de la Comunidad Europea no hace
                      falta presentar fotocopia del pasaporte ARGENTINO.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059773/iconos%20requisitos/ICONOS_Mesa_de_trabajo_1_eop87t.png"
                    alt="Pasaporte"
                    height="300px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Fotocopia simple del ticket aéreo.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059773/iconos%20requisitos/ICONOS-02_ovxgzw.png"
                    alt="Ticket Aereo"
                    height="230px"
                    responsiveHeight="180px"
                  />
                </DocumentationSegment>

                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      ml="100px"
                    >
                      Fotocopia del DNI (frente y dorso).
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059773/iconos%20requisitos/ICONOS-03_nhqqzz.png"
                    alt="Fotocopia del DNI"
                    height="170px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                    >
                      Movimientos migratorios del año en curso y el anterior.{" "}
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-04_nkafbo.png"
                    alt="Globo terraqueo"
                    height="230px"
                  />
                </DocumentationSegment>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      minWidth="330px"
                      ml="30px"
                    >
                      Constancia de CUIT/CUIL.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-05_px4p7t.png"
                    alt="Constancia de Cuit/Cuil"
                    height="200px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      mr="50px"
                    >
                      Autorización para el despacho de Aduana.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-06_byosqw.png"
                    alt="Checklist"
                    height="200px"
                  />
                </DocumentationSegment>
                <DocumentationSegment direction="row-reverse">
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      ml="140px"
                    >
                      Declaración jurada.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-07_sztjd1.png"
                    alt="Documentos"
                    height="230px"
                  />
                </DocumentationSegment>
                <DocumentationSegment>
                  <MaterialTextContainer>
                    <Paragraph
                      color="var(--marineBlue)"
                      size="13px"
                      family="Manrope-regular"
                      textAlign="left"
                      lineHeight="1.5"
                      weight="600"
                      mr="50px"
                    >
                      Fotocopia certificada de documentación <br />
                      que acredite residencia en el exterior.
                    </Paragraph>
                  </MaterialTextContainer>

                  <Image
                    src="https://res.cloudinary.com/dvxeh973m/image/upload/v1679059774/iconos%20requisitos/ICONOS-08_c4mdxm.png"
                    alt="Calendario"
                    height="200px"
                  />
                </DocumentationSegment>
              </SegmentsContainer>
              <Paragraph
                color="var(--marineBlue)"
                size="13px"
                family="Manrope-regular"
                textAlign="left"
                lineHeight="1.5"
                weight="600"
              >
                En caso de no residir en Argentina puede certificar por
                consulado argentino, con un notario y realizando
                <br /> el apostillado, de forma remota con nuestro escribano en
                Buenos Aires (c/certificación: EUR 50).
              </Paragraph>
            </DocumentationContainer>
          </>
        )}
      </SectionContainer>
    </>
  );
};
