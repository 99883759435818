import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../config'
import { ContactForm } from './sections/ContactForm'
import { ContactInfo } from './sections/ContactInfo'

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${mediaQueries.mobile}{
    flex-direction: column;
  }
`

export const Contact = () => {
  return (
    <ContactContainer>
      <ContactInfo />
      <ContactForm />
    </ContactContainer>
  )
}
