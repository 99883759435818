import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Title } from "../../components";
import { exactVolumes } from "../../data";
import { ChakraProvider } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { ButtonContainer, StyledButton } from "./Step2";
import { chooseVolumesCalculator, chooseID } from "../../store/rootSlice/rootSlice";
import { ConfirmDialog } from "../../components/confirmDialog/ConfirmDialog";
import { getLeads, updateLeadsIds } from "../../config/firebase/firebase";
import { chooseStep } from "../../store/steperSlice/SteperSlice";

const ExactVolumeContainer = styled.div`
  width: 100%;
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const SectionContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  position: relative;
  width: 80%;
  padding-left: 10px;
  padding-bottom: ${(props) => props.paddingBottom || "20px"};
  padding-top: ${(props) => props.paddingTop || "0px"};

  @media (max-width: 1000px) {
    width: ${(props) => props.width || "80%"};
  }
`;

const CardIconContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1160px) {
    width: 95%;
  }
  @media (max-width: 1000px) {
    /* width: 90%; */
    width: 95%;
  }
  @media (max-width: 500px) {
    width: 110%;
  }
`;

const CardIcon = styled(Image)`
  position: absolute;
  width: ${(props) => props.width || "600px"};
  top: ${(props) => props.top || "-100px"};
  left: ${(props) => props.left || "0px"};
  max-width: 600px;
  color: var(--marineBlue);

  @media (max-width: 1160px) {
    width: 500px;
    top: -60px;
  }
  @media (max-width: 1000px) {
    max-width: 1200px;
    width: 90%;
    top: 590px;
    left: 5%;
  }
`;

// const ProgressBar = styled(Progress)`
//   position: absolute;
//   width: 580px;
//   top: -130px;
//   left: 10px;

//   @media (max-width: 1160px) {
//     width: 480px;
//   }

//   @media (max-width: 1000px) {
//     width: 86%;
//     top: 540px;
//     left: 6%;
//   }
// `;

const ProgressBarSpan = styled.span`
  font-size: 16px;
  font-family: "Manrope-Bold";
  color: var(--marineBlue);
  position: absolute;
  top: -134px;
  left: 10px;
  z-index: 2;
  width: 90%;

  @media (max-width: 1160px) {
    left: 10px;
  }
  @media (max-width: 1000px) {
    top: 535px;
    left: 7%;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const ListContainer = styled.div`
  width: 25%;
  position: relative;

  @media (max-width: 1160px) {
    width: 35%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const List = styled.div`
  width: 100%;
  background-color: var(--pearlGrey);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;

  @media (max-width: 1000px) {
    padding: 10px 20px;
    padding-bottom: 20px;
    margin-bottom: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 300px;
  }
`;

const ListSpan = styled.span`
  font-size: 16px;
  font-family: "Manrope-Bold";
  color: var(--marineBlue);
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "white" : "transparent")};

  @media (max-width: 1160px) {
    font-size: 16px;
  }
`;

const ItemsContainer = styled.div`
  width: 80%;
  padding: 20px 30px;
  flex-direction: column;
  border-radius: 30px;
  border: 1px solid var(--footerGrey);

  @media (max-width: 1000px) {
    width: 90%;
    padding: 30px 15px 30px 30px;
  }
`;

const ScrollBarContainer = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: var(--pearlGrey);
    margin: 10px;
    @media (max-width: 1000px) {
      margin: 0px;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--footerGrey);
  }
`;

const Items = styled.div`
  width: 98%;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--pearlGrey);
  @media (max-width: 1000px) {
    margin-right: 5px;
    width: 95%;
  }
`;

const ItemsSpan = styled.span`
  user-select: none;
  font-size: 16px;
  font-family: "Manrope-Bold";
  color: var(--footerGrey);
`;

const ItemsButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ItemsButton = styled.button`
  width: 20px;
  height: 20px;
  padding-bottom: ${(props) => (props.padding ? props.padding : "0")};
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--offWhite);
  background-color: var(--orange);
`;

const CardIconContainerTitle = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: -80px;
  left: 15px;

  @media (max-width: 1000px) {
    top: 0px;
    left: 28px;
  }
`;

export const Step4 = () => {

  const [leads, setLeads] = useState({ diplomaticosIds: 0, particularesIds: 0 });
  const activeName = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);

  const [nameActive, setNameActive] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [formVolumes, setformVolumes] = useState(exactVolumes);
  const [estado, setEstado] = useState(1);
  const [truckValues, setTruckValues] = useState(exactVolumes[0]);

  const handleCancel = () => {
    setShowDialog(false);
    window.scrollTo(
      activeName.current.getBoundingClientRect().x,
      activeName.current.getBoundingClientRect().y
    );
  };

  const handleAccept = () => {
    enviarFormulario();
    updateLeads();
  }

  const submitHandler = async (state) => {
    await fetch(
      'https://us-central1-elisharweb-d39e7.cloudfunctions.net/budgetForm',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...state, secret: 'firebaseIsCool'}),
      }
    );
  }

  const getInfo = async () => {
    setLeads(await getLeads());
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getInfo();
      return data;
    };
    getData();
  }, []);

  useEffect(() => {
    if (id !== "diplomaticos" && (id !== "particulares-locales" && id !== "particulares-internacionales")) {
      navigate("/");
      return;
    }
    if (state.root.contact.name === "") {
      dispatch(chooseStep('contact'));
      navigate(`/presupuesto/${id}/contacto`);
      return;
    }
  }, [id, navigate, state, dispatch]);

  const updateLeads = async () => {
    if(id === 'diplomaticos') {
      await updateLeadsIds({ diplomaticosIds: leads.diplomaticosIds + 1, particularesIds: leads.particularesIds });
    } else {
      await updateLeadsIds({ particularesIds: leads.particularesIds + 1, diplomaticosIds: leads.diplomaticosIds });
    }
  }

  const handleIncrement = (e) => {
    const id = Number(e.target.id);

    setformVolumes(
      formVolumes.map((item) => {
        if (item.id === nameActive) {
          const e = item.data.map((element) => {
            if (element.id === id) {
              setTruckValues({
                ...truckValues,
                currentVolume: truckValues.currentVolume + element.volume,
              });
                setEstado(Math.round((truckValues.currentVolume / truckValues.maxVolume) * 10));
              const newElement = { ...element, quantity: element.quantity + 1 };
              return newElement;
            }
            return element;
          });
          const newItem = { ...item, data: e };
          return newItem;
        }
        return item;
      })
    );
  };

  const handleDecrement = (e) => {
    const id = Number(e.target.id);

    setformVolumes(
      formVolumes.map((item) => {
        if (item.id === nameActive) {
          const e = item.data.map((element) => {
            if (element.id === id) {
              setTruckValues({
                ...truckValues,
                currentVolume: truckValues.currentVolume - element.volume,
              });
                setEstado(Math.round((truckValues.currentVolume / truckValues.maxVolume) * 10));
              const newElement = { ...element, quantity: element.quantity - 1 };
              return newElement;
            }
            return element;
          });
          const newItem = { ...item, data: e };
          return newItem;
        }
        return item;
      })
    );
  };

  const Selectedvalues = () => {
    const volumes = formVolumes.map((item) => {
      if (item.id) {
        return {
          ...item,
          data: item.data.filter((element) => element.quantity > 0),
        };
      } else {
        return item;
      }
    });
    return volumes.filter((item) => (item.id ? item.data.length > 0 : null));
  };

  const enviarFormulario = async () => {
    await submitHandler(state);
    navigate(`/presupuesto/${id}/vista-previa`);
  };

  const confirmar = () => {
    const templateValues = Selectedvalues();
    const leadId = id === 'diplomaticos' ? `#DP${leads.diplomaticosIds}` : `#PR${leads.particularesIds}`;
    dispatch(chooseVolumesCalculator({volumes: [...templateValues], truckValues: {...truckValues}}));
    dispatch(chooseID({leadId: leadId, id: id === 'diplomaticos' ? 'diplomaticos' : 'particulares'}));
    setShowDialog(true);
  }

  return (
    <>
      <ChakraProvider>
        <ExactVolumeContainer>
          <TextContainer>
            <Title
              color="var(--marineBlue)"
              size="26px"
              family="Manrope-ExtraBold"
            >
              {" "}
              Calcula el volumen de tu mudanza{" "}
            </Title>
          </TextContainer>

          <SectionContainer ref={activeName}>
            <CardIconContainer>
              <ProgressBarSpan>
                {/* {" "}
                {Math.round(
                  (truckValues.currentVolume / truckValues.maxVolume) * 100
                )}{" "}
                %{" "} */}
                Volumen aproximado: { truckValues.currentVolume.toFixed(2) } m3 (recordá que el volumen de tu mudanza puede variar).
              </ProgressBarSpan>
              {/* <ProgressBar
                size="lg"
                hasStripe
                isAnimated
                value={Math.round(
                  (truckValues.currentVolume / truckValues.maxVolume) * 100
                )}
              /> */}
              {estado > 9 && <CardIcon src="/images/camion 1.png" />}
              {estado <= 9 && <CardIcon src="/images/camion-02.png" />}
              {estado <= 8 && <CardIcon src="/images/camion-03.png" />}
              {estado <= 7 && <CardIcon src="/images/camion-04.png" />}
              {estado <= 6 && <CardIcon src="/images/camion-06.png" />}
              {estado <= 5 && <CardIcon src="/images/camion-08.png" />}
              {estado <= 4 && <CardIcon src="/images/camion-10.png" />}
              {estado <= 3 && <CardIcon src="/images/camion-11.png" />}
              {estado <= 2 && <CardIcon src="/images/camion-12.png" />}
              {estado <= 1 && <CardIcon src="/images/camion-13.png" />}
            </CardIconContainer>
            <ListContainer>
              <Title
                color="var(--footerGrey)"
                family="Manrope-SemiBold"
                padding="10px 30px"
                size="14px"
              >
                {" "}
                Selecciona un ambiente{" "}
              </Title>
              <List>
                {exactVolumes.map((e) => {
                  return (
                    <ListSpan
                      active={e.id === nameActive}
                      key={e.id * 2}
                      onClick={() => setNameActive(e.id)}
                    >
                      {" "}
                      {e.name}{" "}
                    </ListSpan>
                  );
                })}
              </List>
            </ListContainer>
          </SectionContainer>
          <TextContainer width="90%" paddingTop="50px" paddingBottom="0px">
            {truckValues.quantity > 1 && (
              <CardIconContainerTitle>
                <Title
                  color="var(--footerGrey)"
                  family="Manrope-SemiBold"
                  size="14px"
                >
                  {" "}
                  Cantidad de camiones completos: {truckValues.quantity}{" "}
                </Title>
                {/* <CardIcon top='0px' left='280px' width='100px' src="/images/camion 1.png" /> */}
              </CardIconContainerTitle>
            )}
            <Title
              color="var(--footerGrey)"
              family="Manrope-SemiBold"
              padding="10px 20px"
              size="14px"
            >
              {" "}
              Selecciona tus objetos{" "}
            </Title>
          </TextContainer>
          <ItemsContainer>
            <ScrollBarContainer>
              {formVolumes
                .find((e) => e.id === nameActive)
                .data.map((e) => {
                  return (
                    <Items key={e.id * 4}>
                      <ItemsSpan> {e.name} </ItemsSpan>
                      <ItemsButtonsContainer>
                        {e.quantity > 0 && (
                          <ItemsButton
                            id={e.id}
                            padding="2px"
                            onClick={handleDecrement}
                          >
                            {" "}
                            -{" "}
                          </ItemsButton>
                        )}
                        {e.quantity > 0 && (
                          <ItemsSpan> {e.quantity} </ItemsSpan>
                        )}
                        <ItemsButton
                          id={e.id}
                          padding="2px"
                          onClick={handleIncrement}
                        >
                          {" "}
                          +{" "}
                        </ItemsButton>
                      </ItemsButtonsContainer>
                    </Items>
                  );
                })}
            </ScrollBarContainer>
          </ItemsContainer>
          <ButtonContainer w="75%">
            <StyledButton btnVolver>Volver</StyledButton>
            <StyledButton onClick={confirmar}>Enviar</StyledButton>
          </ButtonContainer>
          { 
            showDialog && (
              <ConfirmDialog
                title="¿Estás seguro de que querés enviar el formulario?"
                message="Una vez enviado, no podrás modificarlo."
                onConfirm={handleAccept}
                onCancel={handleCancel}
              />
            )
          }
        </ExactVolumeContainer>
      </ChakraProvider>
    </>
  );
};
