import { Link } from "react-router-dom";
import styled from "styled-components";

/*
Titulos
Parrafos
Botones
*/

export const MainTitle = styled.h1`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font size as prop
  font-size: ${(props) => (props.size ? props.size : "20px")};
  font-family: "Manrope-bold";
  // align as prop
  text-align: ${(props) => (props.align ? props.align : "left")};
  width: 100%;
  letter-spacing: 1px;
  line-height: 45px;
  @media (max-width: 576px) {
    font-size: 35px;
  }
`;

export const Title = styled.h2`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font size as prop
  font-size: ${(props) => (props.size ? props.size : "20px")};
  //font weight as prop
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  //font family as prop
  font-family: ${(props) => (props.family ? props.family : "Manrope-bold")};
  // line height as prop
  line-height: ${(props) => props.lineH};
  letter-spacing: ${(props) => props.spacing};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.align};
  margin-top: ${(props) => props.mt};
`;

export const Secondarytitle = styled.h3`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font size as prop
  font-size: ${(props) => (props.size ? props.size : "20px")};
  //font weight as prop
  font-weight: ${(props) => (props.weight ? props.weight : "400")};
  //font family as prop
  font-family: ${(props) => (props.family ? props.family : "Manrope-regular")};
`;

export const Paragraph = styled.p`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font size as prop
  font-size: ${(props) => (props.size ? props.size : "20px")};
  //font weight as prop
  font-weight: ${(props) => (props.weight ? props.weight : "400")};
  //font family as prop
  font-family: ${(props) => (props.family ? props.family : "Manrope-regular")};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  // line height as prop
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterS};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  min-width: ${(props) => props.minWidth};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  @media (max-width: 992px) {
    min-width: unset;
    margin-left: unset;
    margin-right: unset;
  }
`;

export const Button = styled(Link)`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font size as prop
  font-size: ${(props) => (props.size ? props.size : "20px")};

  //font family as prop
  font-family: ${(props) => (props.family ? props.family : "Manrope-regular")};
  // background as prop
  background: ${(props) =>
    props.background ? props.background : "var(--orange)"};
  // border as prop
  border: ${(props) => (props.border ? props.border : "none")};
  border-radius: ${(props) => props.borderRadius || "none"};
  // margin-top as prop
  margin-top: ${(props) => (props.mt ? props.mt : "0")};
  width: ${(props) => (props.width ? props.width : "230px")};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${(props) => props.hoverbackground};
    color: ${(props) => props.hovercolor};
  }
`;

export const TextSpan = styled.span`
  // color as prop
  color: ${(props) => (props.color ? props.color : "var(--offWhite)")};
  //font weight as prop
  font-weight: ${(props) => (props.weight ? props.weight : "400")};
  font-family: ${(props) => props.family || "Manrope-regular"};
`;

export const Image = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  @media (max-width: 992px) {
    height: ${(props) => props.responsiveHeight};
  }
`;
