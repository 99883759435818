export const exactVolumes = [
  {
    maxVolume: 50,
    currentVolume: 0,
    quantity: 1,
  },
  {
    id: 1,
    name: "Living Room",
    data: [
      {
        id: 1,
        name: "Cuerpos de Sofá",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 2,
        name: "Mesa de TV",
        quantity: 0,
        unit: "m3",
        volume: 0.25
      },
      {
        id: 3,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 4,
        name: "Alfombra",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 5,
        name: "Lámpara de pie o piso",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 6,
        name: "Equipo de música",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 7,
        name: "Vitrina",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 8,
        name: "Mesa ratona",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 9,
        name: "Cajonera",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 10,
        name: "Biblioteca grande",
        quantity: 0,
        unit: "m3",
        volume: 0.6
      },
      {
        id: 11,
        name: "Biblioteca pequeña",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 12,
        name: "Mesa de 4 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 13,
        name: "Mesa de 6 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 14,
        name: "Mesa de 8 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 15,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 16,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 17,
        name: "Espejos",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 18,
        name: "Gabinete",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 19,
        name: "Barra",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 20,
        name: "Aparador",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
    ],
  },
  {
    id: 2,
    name: "Salón Comedor",
    data: [
      {
        id: 1,
        name: "Cuerpos de Sofá",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 2,
        name: "Mesa de TV",
        quantity: 0,
        unit: "m3",
        volume: 0.25
      },
      {
        id: 3,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 4,
        name: "Alfombra",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 5,
        name: "Lámpara de pie o piso",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 6,
        name: "Equipo de música",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 7,
        name: "Vitrina",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 8,
        name: "Mesa ratona",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 9,
        name: "Cajonera",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 10,
        name: "Biblioteca grande",
        quantity: 0,
        unit: "m3",
        volume: 0.6
      },
      {
        id: 11,
        name: "Biblioteca pequeña",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 12,
        name: "Mesa de 4 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 13,
        name: "Mesa de 6 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 14,
        name: "Mesa de 8 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 15,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 16,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 17,
        name: "Espejos",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 18,
        name: "Gabinete",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 19,
        name: "Barra",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 20,
        name: "Aparador",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
    ],
  },
  {
    id: 3,
    name: "Cocina",
    data: [
      {
        id: 1,
        name: "Heladera",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 2,
        name: "Freezer",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 3,
        name: "Lavaplatos",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 4,
        name: "Microondas",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 5,
        name: "Mesa",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 6,
        name: "Mesa de 4 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 7,
        name: "Mesa de 6 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 8,
        name: "Mesa de 8 plazas",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 9,
        name: "Biblioteca",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
      {
        id: 10,
        name: "Espejo",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 11,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 12,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 13,
        name: "Pequeño electrodoméstico",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 14,
        name: "Lámpara (de pie, de piso)",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 15,
        name: "Taburete",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 16,
        name: "Tabla de planchar",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 17,
        name: "Lavarropas",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 18,
        name: "Secarropas",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 19,
        name: "Horno",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
    ],
  },
  {
    id: 4,
    name: "Habitación",
    data: [
      {
        id: 1,
        name: "Cama individual",
        quantity: 0,
        unit: "m3",
        volume: 0.8
      },
      {
        id: 2,
        name: "Cama King",
        quantity: 0,
        unit: "m3",
        volume: 2
      },
      {
        id: 3,
        name: "Cama Queen",
        quantity: 0,
        unit: "m3",
        volume: 1.6
      },
      {
        id: 4,
        name: "Colchón individual",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 5,
        name: "Colchón King",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 6,
        name: "Colchón Queen",
        quantity: 0,
        unit: "m3",
        volume: 0.8
      },
      {
        id: 7,
        name: "Mesa de luz",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 8,
        name: "Armario 2 puertas",
        quantity: 0,
        unit: "m3",
        volume: 1.2
      },
      {
        id: 9,
        name: "Armario 3 puertas",
        quantity: 0,
        unit: "m3",
        volume: 1.8
      },
      {
        id: 10,
        name: "Armario 4 puertas",
        quantity: 0,
        unit: "m3",
        volume: 2
      },
      {
        id: 11,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 12,
        name: "Cajonera",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 13,
        name: "Biblioteca",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
      {
        id: 14,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 15,
        name: "Alfombra",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 16,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 17,
        name: "Espejo",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 18,
        name: "Lámpara",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
    ],
  },
  {
    id: 5,
    name: "Jardín",
    data: [
      {
        id: 1,
        name: "Mesa",
        quantity: 0,
        unit: "m3",
        volume: 0.4
      },
      {
        id: 2,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 3,
        name: "Parrilla/BBQ",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 4,
        name: "Podadora",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
    ],
  },
  {
    id: 6,
    name: "Gimnasio",
    data: [
      {
        id: 1,
        name: "Pesa",
        quantity: 0,
        unit: "m3",
        volume: 0.05
      },
      {
        id: 2,
        name: "Barra",
        quantity: 0,
        unit: "m3",
        volume: 0.02
      },
      {
        id: 3,
        name: "Banco",
        quantity: 0,
        unit: "m3",
        volume: 0.25
      },
      {
        id: 4,
        name: "Cinta de correr",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 5,
        name: "Bicicleta fija",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
    ],
  },
  {
    id: 7,
    name: "Instrumentos musicales",
    data: [
      {
        id: 1,
        name: "Piano eléctrico",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 2,
        name: "Piano de 1/4 de cola",
        quantity: 0,
        unit: "m3",
        volume: 1.6
      },
      {
        id: 3,
        name: "Piano de 1/2 cola",
        quantity: 0,
        unit: "m3",
        volume: 1.7
      },
      {
        id: 4,
        name: "Piano de cola entera",
        quantity: 0,
        unit: "m3",
        volume: 2.2
      },
      {
        id: 5,
        name: "Piano vertical",
        quantity: 0,
        unit: "m3",
        volume: 1.9
      },
      {
        id: 6,
        name: "Guitarra acústica",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 7,
        name: "Guitarra eléctrica",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 8,
        name: "Bajo",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 9,
        name: "Violín",
        quantity: 0,
        unit: "m3",
        volume: 0.05
      },
      {
        id: 10,
        name: "Cello",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 11,
        name: "Contrabajo",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 12,
        name: "Cuerpos de batería",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 13,
        name: "Acordeón / Bandoneón",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 14,
        name: "Tuba",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 15,
        name: "Trompeta",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 16,
        name: "Trombón",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 17,
        name: "Amplificador",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 18,
        name: "Speaker",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
    ],
  },
  {
    id: 8,
    name: "Oficina",
    data: [
      {
        id: 1,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 2,
        name: "Cuerpos de sofá",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 3,
        name: "Mesa",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 4,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 5,
        name: "Escritorio",
        quantity: 0,
        unit: "m3",
        volume: 0.7
      },
      {
        id: 6,
        name: "PC de escritorio",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 7,
        name: "Cajonera",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 8,
        name: "Biblioteca",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
      {
        id: 9,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 10,
        name: "Alfombra",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 11,
        name: "Mesa ratona",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 12,
        name: "Lámpara",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
    ],
  },
  {
    id: 9,
    name: "Play Room",
    data: [
      {
        id: 1,
        name: "Cuerpos de sofá",
        quantity: 0,
        unit: "m3",
        volume: 1
      },
      {
        id: 2,
        name: "Mesa",
        quantity: 0,
        unit: "m3",
        volume: 0.5
      },
      {
        id: 3,
        name: "Silla",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 4,
        name: "Mesa ratona",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 5,
        name: "TV",
        quantity: 0,
        unit: "m3",
        volume: 0.15
      },
      {
        id: 6,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 7,
        name: "Cajonera",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 8,
        name: "Mesa de pool",
        quantity: 0,
        unit: "m3",
        volume: 1.5
      },
      {
        id: 9,
        name: "Biblioteca",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
      {
        id: 10,
        name: "Lámpara",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
    ],
  },
  {
    id: 10,
    name: "Garage/Sótano/Altillo",
    data: [
      {
        id: 1,
        name: "Caja de herramientas",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 2,
        name: "Bicicleta",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 3,
        name: "Lámpara",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 4,
        name: "Estante",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 5,
        name: "Biblioteca",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
    ],
  },
  {
    id: 11,
    name: "Extras",
    data: [
      {
        id: 1,
        name: "Cuadro pequeño",
        quantity: 0,
        unit: "m3",
        volume: 0.016
      },
      {
        id: 2,
        name: "Cuadro mediano",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 3,
        name: "Cuadro grande",
        quantity: 0,
        unit: "m3",
        volume: 0.3
      },
      {
        id: 4,
        name: "Caja pequeña",
        quantity: 0,
        unit: "m3",
        volume: 0.06
      },
      {
        id: 5,
        name: "Caja mediana",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 6,
        name: "Caja grande",
        quantity: 0,
        unit: "m3",
        volume: 0.2
      },
      {
        id: 7,
        name: "Valija pequeña",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 8,
        name: "Valija grande",
        quantity: 0,
        unit: "m3",
        volume: 0.25
      },
      {
        id: 9,
        name: "Aire Acondicionado",
        quantity: 0,
        unit: "m3",
        volume: 0.35
      },
      {
        id: 10,
        name: "Estufa",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
      {
        id: 11,
        name: "Ventilador",
        quantity: 0,
        unit: "m3",
        volume: 0.1
      },
    ],
  },
];

