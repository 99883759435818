import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Paragraph, Steper, Title } from '../../components';
import { getLeads, updateLeadsIds } from '../../config/firebase/firebase';
import { chooseID, chooseVolumes } from '../../store/rootSlice/rootSlice';
import { chooseStep } from '../../store/steperSlice/SteperSlice';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding: ${(props) => (props.padding)};
`;
const InputRowContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 750px) {
    width: 90%;
    justify-content: center;
  }
  @media (max-width: 570px) {
    width: 70%;
  }
`;
const TitleContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 750px) {
    justify-content: center;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`;
const ButtonContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: ${(props) => (props.one ? "flex-end" : "space-between")};
  align-items: center;
  padding: ${(props) => (props.padding || "40px 0px 100px 0px")};
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`;

const StyledButton = styled.button`
  padding: ${(props) => (props.btnVolver ? "15px 0px" : "15px 40px")};
  border-radius: 10px;
  font-family: "Manrope-bold";
  font-size: 16px;
  border: none;
  background-color: ${(props) =>
    props.btnVolver ? "transparent" : "var(--orange)"};
  color: ${(props) => (props.btnVolver ? "black" : "var(--offWhite)")};
  text-decoration: ${(props) => (props.btnVolver ? "underline" : "none")};
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: transparent;
  border: ${props => props.active ? '2px solid var(--orange)' : '2px solid var(--marineBlue)'};
  cursor: pointer;
  /* outline: ${props => props.active ? '2px solid var(--orange)' : '2px solid var(--marineBlue)'}; */
  transform: ${props => props.active ? 'scale(1.02)' : '1'};
  width: 150px;
  height: 150px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  gap: 10px;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 125px;
    height: 125px;
  }
  @media (max-width: 400px) {
    width: 100px;
    height: 100px;
  }

  span{
    font-family: 'Manrope-bold';
    font-size: 14px;
    height: 25px;
    color: ${props => props.active ? 'var(--orange)' : 'var(--marineBlue)'};
    text-align: center;
    user-select: none;
    @media (max-width: 1000px) {
      font-size: 12px;
      height: 25px;
    }
    @media (max-width: 750px) {
      font-size: 12px;
    }
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
`;

const TextContainer = styled.div`
  width: 60%;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`

const StyledSpan = styled.span`
  font-family: "Manrope-Bold";
  font-size: 22px;
  color: var(--marineBlue);
  outline: none;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
`

const CardIcon = styled.img`
  margin-left: ${(props) => (props.ml ? props.ml : "0")};
  height: 70px;
  @media (max-width: 750px) {
    height: 55px;
  }
`;

const StyledInput = styled.textarea`
  width: 100%;
  height: 210px;
  border: 2px solid var(--marineBlue);
  border-radius: 10px;
  /* outline: 2px solid var(--marineBlue); */
  font-size: 1.3rem;
  padding: 15px 0px 0px 25px;
  resize: none;
  font-family: "Manrope-medium";
  font-size: 19px;
  /* transform: translate(0, -23px) scale(0.6); */

  &::placeholder {
    color: var(--pearlGrey);
    font-family: 'Manrope-medium';
  }

  &:focus {
    outline: 1px solid var(--marineBlue);
    transition: all 0.3s ease-in-out;
  }
`

export const Step3 = () => {

  const [leads, setLeads] = useState({ diplomaticosIds: 0, particularesIds: 0 });
  const [cardValue, setCardValue] = useState('');
  const textArea = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [isValidForm, setIsValidForm] = useState(null);

  const handleReturn = () => {
    navigate(`/presupuesto/${id}/volumenes`);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let templateParams = {
      transportTypes: cardValue,
      description: textArea.current.value,
    };
    const leadId = id === 'diplomaticos' ? `#DP${leads.diplomaticosIds}` : `#PR${leads.particularesIds}`;
    dispatch(chooseVolumes(templateParams));
    dispatch(chooseID({leadId, id: id === 'diplomaticos' ? 'diplomaticos' : 'particulares'}));
    const newState = { ...state.root };
    newState.volumes = templateParams;
    newState.id = {leadId, id: id === 'diplomaticos' ? 'diplomaticos' : 'particulares'};
    try {
      await fetch(
      'https://us-central1-elisharweb-d39e7.cloudfunctions.net/simpleBudgetForm',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...newState, secret: 'firebaseIsCool'}),
      });
      updateLeads();
    } catch (error) {
      console.log(error);
    }
    navigate(`/presupuesto/${id}/vista-previa`);
  };

  const getInfo = async () => {
    setLeads(await getLeads());
  };

  const updateLeads = async () => {
    if(id === 'diplomaticos') {
      await updateLeadsIds({ diplomaticosIds: leads.diplomaticosIds + 1, particularesIds: leads.particularesIds });
    } else {
      await updateLeadsIds({ particularesIds: leads.particularesIds + 1, diplomaticosIds: leads.diplomaticosIds });
    }
  }

  const handleVolumes = () => {
    let templateParams = {
      transportTypes: cardValue,
      description: textArea.current.value,
    };
    dispatch(chooseVolumes(templateParams));
    navigate(`/presupuesto/${id}/calcula-tu-volumen`)
  }

  useEffect(() => {
    const getData = async () => {
      const data = await getInfo();
      return data;
    };
    getData();
  }, []);

  useEffect(() => {
    let interval;
    interval = setTimeout(() => {
      setIsValidForm(null);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isValidForm]);

  useEffect(() => {
    const getInfo = async () => {
      setLeads(await getLeads());
    };
    getInfo();
  }, []);

  useEffect(() => {
    if (id !== "diplomaticos" && id !== "particulares-locales" && id !== "particulares-internacionales") {
      navigate("/");
      return;
    }
    if(state.root.contact.name === '' || state.root.details.from.country === ''){
      dispatch(chooseStep('contact'));
      navigate(`/presupuesto/${id}/contacto`);
      return;
    }
    dispatch(chooseStep('volume'))
    const { transportTypes } = state.root.volumes;
    setCardValue(transportTypes);
  }, [id, navigate, state, setCardValue, dispatch]);

  return (
    <>
      <StyledForm padding='100px 0px'>
        <Steper />
        <TitleContainer>
          <Title
            color="var(--orange)"
            family="Manrope-bold"
            size="25px"
            padding="20px 0 0px 0"
          >
            ¿Qué querés trasladar?
          </Title>
        </TitleContainer>
        <InputRowContainer>
          <IconContainer active={cardValue === 'Vivienda completa'} onClick={() => setCardValue('Vivienda completa')}>
            <CardIcon src='https://res.cloudinary.com/dvxeh973m/image/upload/v1675021001/iconos/iconos_formulario_Mesa_de_trabajo_1_jkjzgc.png'></CardIcon>
            <span>Vivienda completa</span>
          </IconContainer>
          <IconContainer active={cardValue === 'Parte de la vivienda'} onClick={() => setCardValue('Parte de la vivienda')}>
            <CardIcon src='https://res.cloudinary.com/dvxeh973m/image/upload/v1675021001/iconos/iconos_formulario-02_lnta3p.png'></CardIcon>
            <span>Parte de la vivienda</span>
          </IconContainer>
          <IconContainer active={cardValue === 'Algunos muebles'} onClick={() => setCardValue('Algunos muebles')}>
            <CardIcon src='https://res.cloudinary.com/dvxeh973m/image/upload/v1675021001/iconos/iconos_formulario-03_nrhyea.png'></CardIcon>
            <span>Algunos muebles</span>
          </IconContainer>
          <IconContainer active={cardValue === 'Algunas cajas o materiales'} onClick={() => setCardValue('Algunas cajas o materiales')}>
            <CardIcon src='https://res.cloudinary.com/dvxeh973m/image/upload/v1675021001/iconos/iconos_formulario-04_cy4c2y.png'></CardIcon>
            <span>Algunas cajas o materiales</span>
          </IconContainer>
        </InputRowContainer>
        { cardValue !== '' && 
        (<><TitleContainer>
          <Title
            color="var(--orange)"
            family="Manrope-bold"
            size="25px"
            padding="20px 0 0px 0"
          >
            Describí lo que querés trasladar
          </Title>
        </TitleContainer><InputRowContainer>
            <StyledInput
              id="description"
              name="description"
              placeholder='Descripción'
              ref={textArea}
              />
          </InputRowContainer>
          <TextContainer>
            <Paragraph color='var(--marineBlue)' size='22px' family='Manrope-Bold' textAlign='left'>
              Para presupuestos más precisos, calcula el <StyledSpan onClick={handleVolumes}> volumen aquí </StyledSpan>
            </Paragraph>
          </TextContainer>
          
          <ButtonContainer>
            <StyledButton btnVolver onClick={handleReturn}>
              Volver
            </StyledButton>
            <StyledButton onClick={onSubmit}>SIGUIENTE</StyledButton>
          </ButtonContainer></>)
          }
      </StyledForm>
    </>
  )
}
