import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Paragraph, TextSpan, Title } from "../../../components";

const BannerSection = styled.section`
  width: 100%;
  height: 90vh;
  background-image: url("https://res.cloudinary.com/dvxeh973m/image/upload/v1672325048/Banners/pexels-ketut-subiyanto-4245992_tqnpht.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 7%;
  gap: 10px;
  margin: 0 auto;
  width: 100%;
  margin-top: 50px;
  @media (min-width: 1950px) {
    padding: 0px 9.5%;
  }
`;

const LinkStyle = styled(Link)`
  /* text-decoration: underline;
  text-decoration-color: var(--orange); */
  color: var(--offWhite);
  font-weight: 600;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: var(--orange);
    transform-origin: right;
    transition: transform 0.3s ease-in-out;
  }
`;
export const Banner = () => {
  return (
    <BannerSection>
      <BannerText>
        <Title
          size="38px"
          family="Manrope-regular"
          color="var(--offWhite)"
          lineH="45px"
        >
          Planifica tu <TextSpan color="var(--orange)">mudanza</TextSpan> <br />{" "}
          a donde quieras!
        </Title>
        <Paragraph size="17px">
          Completa el formulario de{" "}
          <LinkStyle to="/presupuesto">presupuesto online</LinkStyle>
        </Paragraph>
      </BannerText>
    </BannerSection>
  );
};
