import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { Input, Steper, Title } from "../../components";
import { schemaDetails } from "../../config";
import { chooseDetails } from "../../store/rootSlice/rootSlice";
import { chooseStep } from "../../store/steperSlice/SteperSlice";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding: ${(props) => (props.padding)};
`;
export const InputRowContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const TitleContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`;
export const ButtonContainer = styled.div`
  width: ${(props) => (props.w || "60%")};
  display: flex;
  justify-content: ${(props) => (props.one ? "flex-end" : "space-between")};
  align-items: center;
  padding: 40px 0px 100px 0px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
  @media (max-width: 550px) {
    flex-direction: ${(props) => (props.column ? "column" : "row")};
    gap: ${(props) => (props.column ? "20px" : "0px")};
  }
`;

export const StyledButton = styled.button`
  padding: ${(props) => (props.btnVolver ? "15px 0px" : "15px 40px")};
  border-radius: 10px;
  font-family: "Manrope-bold";
  font-size: 16px;
  border: none;
  background-color: ${(props) =>
    props.btnVolver ? "transparent" : "var(--orange)"};
  color: ${(props) => (props.btnVolver ? "black" : "var(--offWhite)")};
  text-decoration: ${(props) => (props.btnVolver ? "underline" : "none")};
  cursor: pointer;
`;

export const Step2 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector((state) => state.details);
  const state = useSelector((state) => state);
  const [isValidForm, setIsValidForm] = useState(null);

  const apiKey = 'AIzaSyCOvDohOYjucYwgQnHG1o4HAnBs5svCPp4';
  const mapApi = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;

  const loadAsyncScript = (src) => {
    return new Promise( resolve => {
      const script = document.createElement('script');
      Object.assign(script, {
        type: 'text/javascript',
        async: true,
        src
      });
      script.addEventListener('load', () => {
        resolve(script);
      });
      document.head.appendChild(script);
    });
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      details,
    },
    mode: "onSubmit",
    rules: {
      autoComplete: 'off'
    },
    reValidateMode: "onSubmit",
    resolver: yupResolver(schemaDetails),
  });

  useEffect(() => {

    const initAutocomplete = () => {

      const city = document.getElementById("city");
      const country = document.getElementById("country");
      const toCity = document.getElementById("toCity");
      const toCountry = document.getElementById("toCountry");

      const getAdress = (places, input) => {
        const place = places.getPlace();
        const address = {
          city: null,
          country: null,
          zipCode: null,
          state: null,
        }
        if(!Array.isArray(place?.address_components)) return '';

        place?.address_components.forEach((item) => {
          if(item.types.includes('locality')) address.city = item.long_name;
          if(item.types.includes('country')) address.country = item.long_name;
          if(item.types.includes('postal_code')) address.zipCode = item.long_name;
          if(item.types.includes('administrative_area_level_1')) address.state = item.long_name;
        });
        if(address.city) setValue(input === 'from' ? 'city' : 'toCity', address.city);
        if(address.state && address.city) setValue(input === 'from' ? 'city' : 'toCity', address.city + ', ' + address.state);
        if(address.country) setValue(input === 'from' ? 'country' : 'toCountry', address.country);
        if(address.zipCode) setValue(input === 'from' ? 'zipCode' : 'toZipCode', address.zipCode);
      }

      const autocompleteCity = new window.google.maps.places.Autocomplete(city); 
      autocompleteCity.setFields(["address_component", "geometry"]);
      autocompleteCity.addListener("place_changed", () => getAdress(autocompleteCity, 'from'));

      const autocompleteCountry = new window.google.maps.places.Autocomplete(country); 
      autocompleteCountry.setFields(["address_component", "geometry"]);
      autocompleteCountry.addListener("place_changed", () => getAdress(autocompleteCountry, 'from'));

      const autocompleteToCity = new window.google.maps.places.Autocomplete(toCity); 
      autocompleteToCity.setFields(["address_component", "geometry"]);
      autocompleteToCity.addListener("place_changed", () => getAdress(autocompleteToCity, 'to'));

      const autocompleteToCountry = new window.google.maps.places.Autocomplete(toCountry); 
      autocompleteToCountry.setFields(["address_component", "geometry"]);
      autocompleteToCountry.addListener("place_changed", () => getAdress(autocompleteToCountry, 'to'));

    }

    const initMapScript = async () => {
      if(window.google) {
        return Promise.resolve();
      }
      const src = mapApi;
      return loadAsyncScript(src);
    }

    initMapScript().then(() => {
      initAutocomplete();
    });
  }, [mapApi, control, setValue]);

  const handleReturn = () => {
    navigate(`/presupuesto/${id}/contacto`);
  };
  const onSubmit = (data) => {
    const {
      date,
      flexDate,
      country,
      city,
      address,
      zipCode,
      house,
      apartment,
      other,
      toCountry,
      toCity,
      toAddress,
      toZipCode,
      toHouse,
      toApartment,
      toOther,
    } = data;
    let templateParams = {
      date: date,
      flexDate: flexDate,
      from: {
        country: country,
        city: city,
        address: address,
        zipCode: zipCode,
        house: house,
        apartment: apartment,
        other: other,
      },
      to: {
        country: toCountry,
        city: toCity,
        address: toAddress,
        zipCode: toZipCode,
        house: toHouse,
        apartment: toApartment,
        other: toOther,
      },
    };
    dispatch(chooseDetails(templateParams));
    navigate(`/presupuesto/${id}/volumenes`);
  };

  useEffect(() => {
    let interval;
    interval = setTimeout(() => {
      setIsValidForm(null);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isValidForm]);

  useEffect(() => {
    if (id !== "diplomaticos" && id !== "particulares-locales" && id !== "particulares-internacionales") {
      navigate("/");
      return;
    }
    if(state.root.contact.name === ''){
      dispatch(chooseStep('contact'))
      navigate(`/presupuesto/${id}/contacto`);
      return;
    }
    dispatch(chooseStep('details'))
    const { date, flexDate, to, from } = state.root.details;
    setValue('date', date);
    setValue('flexDate', flexDate);
    setValue('toCountry', to.country);
    setValue('toCity', to.city);
    setValue('toAddress', to.address);
    setValue('toZipCode', to.zipCode);
    setValue('toOther', to.other);
    setValue('country', from.country);
    setValue('city', from.city);
    setValue('address', from.address);
    setValue('zipCode', from.zipCode);
    setValue('other', from.other);

    if(id === 'particulares-locales'){
      setValue('toCountry', 'Argentina');
      setValue('country', 'Argentina');
    }
  }, [id, navigate, state, setValue, dispatch]);

  return (
    <>
      <StyledForm autoComplete="off" padding='100px 0px 0px 0px' onSubmit={handleSubmit(onSubmit)}>
        <Steper />
        <TitleContainer>
          <Title
            color="var(--orange)"
            family="Manrope-bold"
            size="25px"
            padding="20px 0 0px 0"
          >
            Origen de la mudanza
          </Title>
        </TitleContainer>
        <InputRowContainer>
          <Input
            id="date"
            name="date"
            width="50%"
            type="date"
            placeholder=""
            label="Fecha estimada de mudanza"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            min={new Date().toISOString().split("T")[0]}
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.date}
            // transform={ control._getWatch('date', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
            transform='translate(0, -23px) scale(0.6)'
          />
          <Input
            id="flexDate"
            name="flexDate"
            min={new Date().toISOString().split('T')[0]}
            width="50%"
            type="date"
            placeholder=""
            label="Fecha flexible"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.flexDate}
            // transform={ control._getWatch('flexDate', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
            transform='translate(0, -23px) scale(0.6)'
          />
        </InputRowContainer>
        <InputRowContainer>
          <Input
            id="country"
            name="country"
            width="50%"
            type="text"
            placeholder=""
            label="País"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.country}
            transform={ control._getWatch('country', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
          <Input
            id="city"
            name="city"
            width="50%"
            type="text"
            placeholder=""
            label="Ciudad"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.city}
            transform={ control._getWatch('city', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
        </InputRowContainer>
        <InputRowContainer>
          <Input
            id="address"
            name="address"
            width="70%"
            type="text"
            placeholder=""
            label="Calle (opcional)"
            height="80px"
            widthR="70%"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.address}
            transform={ control._getWatch('address', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
          <Input
            id="zipCode"
            name="zipCode"
            width="30%"
            type="text"
            placeholder=""
            label="Código postal"
            height="80px"
            widthR="30%"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.zipCode}
            transform={ control._getWatch('zipCode', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
        </InputRowContainer>
        <InputRowContainer>
            <Input
              id="house"
              name="house"
              type="checkbox"
              label="Casa"
              position="static"
              width="25px"
              height="25px"
              paddingLeftR="30px"
              labelWidth="100%"
              border="2px solid var(--pearlGrey)"
              borderRadius="10px"
              outline="none"
              widthInput="40px"
              placeholderColor="#999999ce"
              placeholderFont="Manrope-medium"
              fontSize="19px"
              widthR="auto"
              noTransform
              flexDirection="row"
              control={control}
            />
            <Input
              id="apartment"
              name="apartment"
              type="checkbox"
              position="static"
              noTransform
              labelWidth="100%"
              widthR="200px"
              widthInput="40px"
              paddingLeftR="30px"
              label="Departamento"
              width="25px"
              height="25px"
              border="2px solid var(--pearlGrey)"
              borderRadius="10px"
              outline="none"
              placeholderColor="#999999ce"
              placeholderFont="Manrope-medium"
              fontSize="19px"
              flexDirection="row"
              control={control}
            />
          <Input
            id="other"
            name="other"
            width="46.3%"
            type="text"
            placeholder="Otro (indicar)"
            label=""
            flexDirection="row"
            height="60px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.other}
          />
        </InputRowContainer>
        <TitleContainer>
          <Title
            color="var(--orange)"
            family="Manrope-bold"
            size="25px"
            padding="20px 0 0px 0"
          >
            Destino de la mudanza
          </Title>
        </TitleContainer>
        <InputRowContainer>
          <Input
            id="toCountry"
            name="toCountry"
            width="50%"
            type="text"
            placeholder=""
            label="País"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.toCountry}
            transform={ control._getWatch('toCountry', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
          <Input
            id="toCity"
            name="toCity"
            width="50%"
            type="text"
            placeholder=""
            label="Ciudad"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.toCity}
            transform={ control._getWatch('toCity', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
        </InputRowContainer>
        <InputRowContainer>
          <Input
            id="toAddress"
            name="toAddress"
            width="70%"
            type="text"
            placeholder=""
            label="Calle (opcional)"
            height="80px"
            widthR="70%"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.toAddress}
            transform={ control._getWatch('toAddress', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
          <Input
            id="toZipCode"
            name="toZipCode"
            width="30%"
            type="text"
            placeholder=""
            label="Código postal"
            height="80px"
            widthR="30%"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.toZipCode}
            transform={ control._getWatch('toZipCode', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
        </InputRowContainer>
        <InputRowContainer>
          <Input
            id="toHouse"
            name="toHouse"
            type="checkbox"
            label="Casa"
            position="static"
            width="25px"
            height="25px"
            widthR="auto"
            widthInput="40px"
            paddingLeftR="30px"
            labelWidth="100%"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="none"
            noTransform
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            flexDirection="row"
            control={control}
          />
          <Input
            id="toApartment"
            name="toApartment"
            type="checkbox"
            label="Departamento"
            position="static"
            width="25px"
            height="25px"
            widthR="200px"
            widthInput="40px"
            labelWidth="100%"
            noTransform
            paddingLeftR="30px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="none"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            flexDirection="row"
            control={control}
          />
          <Input
            id="toOther"
            name="toOther"
            width="46.3%"
            type="text"
            placeholder="Otro (indicar)"
            label=""
            flexDirection="row"
            height="60px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
          />
        </InputRowContainer>
        <ButtonContainer>
          <span
            style={{ fontFamily: "Manrope-bold", fontSize: "16px" }}
            onClick={handleReturn}
          >
            {"< "}
            <StyledButton onClick={handleReturn} btnVolver>
              VOLVER
            </StyledButton>
          </span>
          <StyledButton type="submit"> SIGUIENTE {">"}</StyledButton>
        </ButtonContainer>
      </StyledForm>
    </>
  );
};
