export const FaqsFromArg = [
  {
   title: '¿Cómo calculo el volumen de mi mudanza?',
   content: [
    '<div> El volumen de los enseres que se enviaran se calcula multiplicando el <span style="text-decoration: underline;"> ancho x largo x alto </span> de cada objeto en particular y luego sumarlos todos. De todas maneras con el fin de realizar una estimación personal y rápida, tomar como referencia que tanto una heladera convencional como 10 cajas medianas ( 0.40 x 0.60 x 0.40 ) ocupan aproximadamente 1 m3. Además, cuando soliciten el <span style="text-decoration: underline; font-weight: bold;"> <a href="https://www.elishar.com.ar/#/presupuesto">presupuesto ONLINE </a></span>, podrán hacer uso de una tabla automatizada para hacer una estimación del volumen. </div>',
    '<div> Tener en cuenta que a los efectos de la cotización se considera el volúmen bruto, compuesto por los efectos (volúmen neto) más el embalaje. </div>',
   ],
   images: [
    
   ]
  },
  {
   title: '¿Cómo calculan el costo de mi mudanza?',
   content: [
    'El costo de la mudanza se calcula según el <b> volumen </b> para envíos marítimos y según <b> peso </b> y <b> volúmen </b> combinados para envíos aéreos ***0***(¿Cómo calculo el volumen de mi mudanza?),.*** En base a esto, se tiene en cuenta el coste de los siguientes servicios:',
    '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> Embalaje de los enseres y muebles en el domicilio de origen. </li> <li> Desmontaje de los muebles. </li> <li> Elaboración de la lista de empaque. </li> <li> Carga de los efectos. </li> <li> Transporte hacia nuestro depósito. </li> <li> Transporte al almacén fiscal. </li> <li> Almacenaje de la carga hasta la fecha de salida </li> <li> Realización de trámites requeridos tanto en origen como en destino. </li> <li> Flete marítimo/aéreo/terrestre. </li> <li> Despachos de aduana tanto en origen como en destino. </li> <li> Transporte desde la terminal o almacén fiscal hacia el domicilio final. </li> <li> Descarga de los efectos. </li> <li> Desembalaje y retirada del material sobrante.  </li> <li> Montaje de los muebles. </li> <li> De corresponder, devolución del contenedor.  </li> <li> Seguro. </li> </ul>',
    'Una mudanza cuenta con algunos <b> costos fijos </b> (algunos gastos portuarios, despachos de aduana, transportes, etc.) y otros variables como el flete marítimo, el material de embalaje, personal requerido, gastos de almacén fiscal,algunos gastos portuarios, entre otros. Es importante tener en cuenta que en volúmenes mayores los costos fijos se prorratean, haciendo que, <b>una mudanza más voluminosa sea <span style="text-decoration: underline;">proporcionalmente</span> más económica que una pequeña.</b>',
    'En una mudanza internacional, <b>se pueden estimar de manera aproximada los gastos del puerto de destino</b>. Estos serán provistos por nuestros corresponsales en el extranjero.',
    'En el caso de una mudanza realizada de manera aérea, <b>1 m3 equivale a 167 kg</b>, y se factura según esta regla. A modo de ejemplo, si tiene medio metro cúbico y el peso iguala o supera los 167kg, entonces el monto facturable es por el peso; si tiene un metro cúbico pero el peso no llega a 167kg el monto facturable es de 167 kg.',
    'Igualmente, la manera más fácil de calcular el valor de la mudanza y saber que incluye y que no, es pidiendonos un <span style="text-decoration: underline; font-weight: bold; cursor: pointer"> <a href="https://www.elishar.com.ar/#/presupuesto"> presupuesto ONLINE. </a> </span>',
   ],
  },
  {
   title: '¿Estoy exento de pagar impuestos?',
   content: [
    'En cuanto el titular de la mudanza se enmarque dentro de la reglamentación vigente para el ingreso de equipaje no acompañado <b>no se abonan derechos aduaneros</b> ***3***(¿Cuáles son los requisitos para poder realizar mi mudanza sin pagar derechos aduaneros?).***',
    'Si el titular <b>no cumple con este requisito</b>, existe la alternativa de realizar la mudanza mediante pago de derechos aduaneros.',
   ],
  },
  {
   title: '¿Cuáles son los requisitos para poder realizar mi mudanza sin pagar derechos aduaneros?',
   content: [
    'Para no tener que abonar derechos aduaneros, el titular de la mudanza debe cumplir con los siguientes requisitos:',
    '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> Su estadía en el exterior debe haber sido mayor a un año </li><li> No debe haber estado, en total, más de 60 días en Argentina, en los últimos 365 días, contados desde el día en que retorna al país.  </li><li> y, por último, el contenedor debe arribar al país dentro de los 180 días posteriores a su llegada. </li> </ul>',
   ],
  },
  {
   title: '¿Cómo se contrata el seguro?',
   content: [
    'Para contratar el seguro debe firmar los siguientes formularios:',
    '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> <a href="http://www.intertransins.com/new_site/forms/ITIHHGLARGE2021-07.pdf" target="_blank">FORMULARIOS para la aseguradora</a> </li> </ul>',
    'El monto de la póliza se calcula según el valor de reposición de los efectos en destino. Sugerencia; el valor tiene que ser coherente con el costo real de los efectos.',
   ],
  },
  {
   title: '¿Cuánto tarda todo el proceso?',
   content: [
    'Existen 3 formas de transporte para la realización de una mudanza: tierra, mar y aire ***6***(¿En qué ocasiones se usa cada tipo de transporte?)***. Cada una tiene un proceso distinto y, por lo tanto, sus tiempos también varían. ',
    'En caso de ser <b>marítima</b>, considerando los plazos aduaneros, los servicios, tanto en origen como en destino, el flete marítimo y los distintos transportes, tarda entre dos  y tres meses, siempre y cuando toda la documentación sea entregada en tiempo y forma y no haya eventualidades de fuerza mayor. Los tiempos de una mudanza marítima también varían según la modalidad de envío, cambiando si se utilizan contenedores compartidos o exclusivos. ***7***(¿Qué tipo de contenedores existen?)***',
    'Si su mudanza es <b>aérea</b>, teniendo en cuenta los mismos criterios, el plazo es de, aproximadamente, 2 a 4 semanas.',
    'Por último están los traslados <b>terrestres</b>, utilizados normalmente para mudanzas desde y hacia países fronterizos. Estos tardan entre un mes y un mes y medio.',
   ],
  },
  {
   title: ' ¿En qué ocasiones se usa cada tipo de transporte?',
   content: [
    'La elección del transporte depende del volúmen de la mudanza y la ubicación del origen de la misma.',
    'Para mudanzas de corta distancia, desde países países fronterizos se utilizan <b>transportes terrestres, marítimos o aéreos</b>.',
    'Si el destino se encuentra en Europa, América del Norte, Asia, etc. y el volúmen es mayor a 4 m3, aproximadamente, se envía en general, con un <b>transporte marítimo</b>. De ser menor al volumen mencionado, dependiendo los costos de cada origen, a veces conviene realizar un <b>envío aéreo</b>.',
   ],
  },
  {
   title: '¿Qué tipo de contenedores existen?',
   content: [
    'En una mudanza internacional realizada vía marítima se pueden elegir, según convenga, distintos tipos de contenedores.',
    'Un contenedor de <b>20 pies</b>, tiene una capacidad total aproximada de <b>30 m3</b>.',
    'La opción intermedia es el contenedor de <b>40 pies</b>, en el que entran <b>60 m3</b>. ',
    'Por último, siendo un poco más alto que el anterior, se utiliza el contenedor de <b>40 pies HC</b>, en el que caben, aproximadamente, <b>70 m3</b>.',
    'De todas maneras, existe la posibilidad de no llenar un contenedor, y solo utilizar una parte del mismo. A esto se lo conoce como envío <b>consolidado</b> o, por su sigla en inglés, <b>LCL</b> (Less than Container Load), mientras que utilizar un contenedor de manera <b>exclusiva</b>, se llene o no, es un método de envío conocido como <b>FCL</b> (Full Container Load).',
   ],
   images: [

   ],
  },
]
export const CarsFromArg = [
  {
    title: '¿Puedo enviar cosas dentro del vehículo?',
    content: [
     '<span style="text-decoration: underline;"><b>No</b></span>, al hacer una mudanza, los vehículos y los efectos, necesitan dos despachos diferentes ya que el régimen de exportación es diferente. Por eso es que <b>no pueden mezclarse</b>. ',
    ],
   },
   {
    title: '¿En qué condiciones debo entregarlo para el traslado?',
    content: [
     'Para el traslado de un vehículo se requiere entregarlo a la empresa de una manera específica: ',
     '<ul style="padding: 15px 0px ;padding-left: 30px"><li> Su tanque de gasolina debe estar casi  vacío. </li><li> No debe haber ni accesorios ni efectos dentro ***0***(¿Puedo enviar cosas dentro del vehículo?)*** </li><li> La batería debe estar desconectada. </li><li> Y puede contener una sola rueda de auxilio. </li></ul>',
    ],
   },
   {
    title: '¿Debo pagar impuestos?',
    content: [
     'Al hacer una exportación de un vehículo usado se <b>debe pagar impuestos</b> sobre el valor del mismo, tanto para motos como para automóviles.',
    ],
   },
]
//<span style="text-decoration: underline;">

export const FaqsToArg = [
 {
  title: '¿Cómo calculo el volumen de mi mudanza?',
  content: [
   '<div> El volumen de los enseres que se enviaran se calcula multiplicando el <span style="text-decoration: underline;"> ancho x largo x alto </span> de cada objeto en particular y luego sumarlos todos. De todas maneras con el fin de realizar una estimación personal y rápida, tomar como referencia que tanto una heladera convencional como 10 cajas medianas ( 0.40 x 0.60 x 0.40 ) ocupan aproximadamente 1 m3. Además, cuando soliciten el <span style="text-decoration: underline; font-weight: bold; cursor:pointer;"> <a href="https://www.elishar.com.ar/#/presupuesto">presupuesto ONLINE </a></span>, podrán hacer uso de una tabla automatizada para hacer una estimación del volumen. </div>',
   '<div> Tener en cuenta que a los efectos de la cotización se considera el volúmen bruto, compuesto por los efectos (volúmen neto) más el embalaje. </div>',
  ],
  images: [
   
  ]
 },
 {
  title: '¿Cómo calculan el costo de mi mudanza?',
  content: [
   'El costo de la mudanza se calcula según el <b> volumen </b> para envíos marítimos y según <b> peso </b> y <b> volúmen </b> combinados para envíos aéreos. ***0***(¿Cómo calculo el volumen de mi mudanza?)***,. En base a esto, se tiene en cuenta el coste de los siguientes servicios:',
   '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> Embalaje de los enseres y muebles en el domicilio de origen. </li> <li> Desmontaje de los muebles. </li> <li> Elaboración de la lista de empaque. </li> <li> Carga de los efectos. </li> <li> Transporte hacia nuestro depósito. </li> <li> Transporte al almacén fiscal. </li> <li> Almacenaje de la carga hasta la fecha de salida </li> <li> Realización de trámites requeridos tanto en origen como en destino. </li> <li> Flete marítimo/aéreo/terrestre. </li> <li> Despachos de aduana tanto en origen como en destino. </li> <li> Transporte desde la terminal o almacén fiscal hacia el domicilio final. </li> <li> Descarga de los efectos. </li> <li> Desembalaje y retirada del material sobrante.  </li> <li> Montaje de los muebles. </li> <li> De corresponder, devolución del contenedor.  </li> <li> Seguro. </li> </ul>',
   'Una mudanza cuenta con algunos <b> costos fijos </b> (algunos gastos portuarios, despachos de aduana, transportes, etc.) y otros variables como el flete marítimo, el material de embalaje, personal requerido, gastos de almacén fiscal,algunos gastos portuarios, entre otros. Es importante tener en cuenta que en volúmenes mayores los costos fijos se prorratean, haciendo que, <b>una mudanza más voluminosa sea <span style="text-decoration: underline;">proporcionalmente</span> más económica que una pequeña.</b>',
   'En una mudanza internacional, <b>se pueden estimar de manera aproximada los gastos del puerto de destino</b>. Estos serán provistos por nuestros corresponsales en el extranjero.',
   'En el caso de una mudanza realizada de manera aérea, <b>1 m3 equivale a 167 kg</b>, y se factura según esta regla. A modo de ejemplo, si tiene medio metro cúbico y el peso iguala o supera los 167kg, entonces el monto facturable es por el peso; si tiene un metro cúbico pero el peso no llega a 167kg el monto facturable es de 167 kg.',
   'Igualmente, la manera más fácil de calcular el valor de la mudanza y saber que incluye y que no, es pidiendonos un <span style="text-decoration: underline; font-weight: bold; cursor: pointer"><a href="https://www.elishar.com.ar/#/presupuesto"> presupuesto ONLINE. </a></span>',
  ],
 },
 {
  title: '¿Estoy exento de pagar impuestos?',
  content: [
   'En cuanto el titular de la mudanza se enmarque dentro de la reglamentación vigente para el ingreso de equipaje no acompañado <b>no se abonan derechos aduaneros</b> ***3***(¿Cuáles son los requisitos para poder realizar mi mudanza sin pagar derechos aduaneros?)***.',
   'Si el titular <b>no cumple con este requisito</b>, existe la alternativa de realizar la mudanza mediante pago de derechos aduaneros.',
  ],
 },
 {
  title: '¿Cuáles son los requisitos para poder realizar mi mudanza sin pagar derechos aduaneros?',
  content: [
   'Para no tener que abonar derechos aduaneros, el titular de la mudanza debe cumplir con los siguientes requisitos:',
   '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> Su estadía en el exterior debe haber sido mayor a un año </li><li> No debe haber estado, en total, más de 60 días en Argentina, en los últimos 365 días, contados desde el día en que retorna al país.  </li><li> y, por último, el contenedor debe arribar al país dentro de los 180 días posteriores a su llegada. </li> </ul>',
  ],
 },
 {
  title: '¿Cómo se contrata el seguro?',
  content: [
   'Para contratar el seguro debe firmar los siguientes formularios:',
   '<ul style="padding: 15px 0px ;padding-left: 30px"> <li> <a href="http://www.intertransins.com/new_site/forms/ITIHHGLARGE2021-07.pdf" target="_blank">FORMULARIOS para la aseguradora</a> </li> </ul>',
   'El monto de la póliza se calcula según el valor de reposición de los efectos en destino. Sugerencia; el valor tiene que ser coherente con el costo real de los efectos.',
  ],
 },
 {
  title: '¿Cuánto tarda todo el proceso?',
  content: [
   'Existen 3 formas de transporte para la realización de una mudanza: tierra, mar y aire ***6***(¿En qué ocasiones se usa cada tipo de transporte?)***. Cada una tiene un proceso distinto y, por lo tanto, sus tiempos también varían. ',
   'En caso de ser <b>marítima</b>, considerando los plazos aduaneros, los servicios, tanto en origen como en destino, el flete marítimo y los distintos transportes, tarda entre dos  y tres meses, siempre y cuando toda la documentación sea entregada en tiempo y forma y no haya eventualidades de fuerza mayor. Los tiempos de una mudanza marítima también varían según la modalidad de envío, cambiando si se utilizan contenedores compartidos o exclusivos. ***7***(¿Qué tipo de contenedores existen?)***',
   'Si su mudanza es <b>aérea</b>, teniendo en cuenta los mismos criterios, el plazo es de, aproximadamente, 2 a 4 semanas.',
   'Por último están los traslados <b>terrestres</b>, utilizados normalmente para mudanzas desde y hacia países fronterizos. Estos tardan entre un mes y un mes y medio.',
  ],
 },
 {
  title: ' ¿En qué ocasiones se usa cada tipo de transporte?',
  content: [
   'La elección del transporte depende del volúmen de la mudanza y la ubicación del origen de la misma.',
   'Para mudanzas de corta distancia, desde países países fronterizos se utilizan <b>transportes terrestres, marítimos o aéreos</b>.',
   'Si el origen se encuentra en Europa, América del Norte, Asia, etc. y el volúmen es mayor a 4 m3, aproximadamente, se envía en general, con un <b>transporte marítimo</b>. De ser menor al volumen mencionado, dependiendo los costos de cada origen, a veces conviene realizar un <b>envío aéreo</b>.',
  ],
 },
 {
  title: '¿Qué tipo de contenedores existen?',
  content: [
   'En una mudanza internacional realizada vía marítima se pueden elegir, según convenga, distintos tipos de contenedores.',
   'Un contenedor de <b>20 pies</b>, tiene una capacidad total aproximada de <b>30 m3</b>.',
   'La opción intermedia es el contenedor de <b>40 pies</b>, en el que entran <b>60 m3</b>. ',
   'Por último, siendo un poco más alto que el anterior, se utiliza el contenedor de <b>40 pies HC</b>, en el que caben, aproximadamente, <b>70 m3</b>.',
   'De todas maneras, existe la posibilidad de no llenar un contenedor, y solo utilizar una parte del mismo. A esto se lo conoce como envío <b>consolidado</b> o, por su sigla en inglés, <b>LCL</b> (Less than Container Load), mientras que utilizar un contenedor de manera <b>exclusiva</b>, se llene o no, es un método de envío conocido como <b>FCL</b> (Full Container Load).',
  ],
  images: [

  ],
 },
]

export const CarsToArg = [
  {
    title: '¿Cuáles son los requisitos?',
    content: [
     'Si usted es argentino y está retornando al país, o es un extranjero con residencia permanente en Argentina, para poder importar un vehículo si pago de derechos aduaneros  se necesita cumplir con las siguientes condiciones:',
     '<ul style="padding: 15px 0px ;padding-left: 30px"><li> El Titular debe haber vivido en el exterior más de dos años, y debe poder constatar con el certificado de residencia otorgado por el consulado argentino  </li><li> El vehículo debe estar registrado  a nombre del titular, como mínimo, 3 meses antes de llegar a Argentina ( el titular) </li><li> Debe  visar el título en el consulado argentino del país en el que se encuentren. </li></ul>',
     'En caso de no ser argentino, ni tener la residencia permanente, su vehículo entrará al país con una <b>admisión temporal para turistas</b>: al vehículo se le permite circular durante 3 a 8 meses. Luego de finalizado ese plazo, debe <b>nacionalizarse</b>, prorrogar la admisión temporal o repatriarse.',
     'Por persona solo puede ingresar al país un auto y una moto. Y en caso de llevar dos autos o dos motos, deben ir a nombre de dos personas diferentes.',
     'Tener en cuenta que <span style="text-decoration: underline;">no</span> está permitido el envío de vehículos utilitarios con régimen de mudanza.',
    ],
   },
   {
    title: '¿Puedo enviar cosas dentro del vehículo?',
    content: [
     '<span style="text-decoration: underline;"><b>No</b></span>, al hacer una mudanza, los vehículos y los efectos, necesitan dos despachos diferentes ya que el régimen de exportación es diferente. Por eso es que <b>no pueden mezclarse</b>. ',
    ],
   },
   {
    title: '¿En qué condiciones debo entregarlo para el traslado?',
    content: [
     'Para el traslado de un vehículo se requiere entregarlo a la empresa de una manera específica: ',
     '<ul style="padding: 15px 0px ;padding-left: 30px"><li> Su tanque de gasolina debe estar casi  vacío. </li><li> No debe haber ni accesorios ni efectos dentro ***1***(¿Puedo enviar cosas dentro del vehículo?)*** </li><li> La batería debe estar desconectada. </li><li> Y puede contener una sola rueda de auxilio. </li></ul>',
    ],
   },
   {
    title: '¿Debo pagar impuestos?',
    content: [
     'Para conocer el valor del vehículo en el país de origen, la Aduana de Argentina se informará mediante las principales páginas web de venta de vehículos en el país de origen.',
     'Si el valor de un <b>automóvil</b> en origen es menor a U$D 15.000 CIF o U$D 13.500 FOB ***4***(¿Qué son los costos CIF y FOB?¿En qué influye para la importación de mi vehículo?)*** <b>no deberá pagar impuestos</b>.',
     'Si superan estos valores <b>deberán pagar impuestos</b> al ingresar al país. ',
     'Las <b>motos</b> siempre pagan <b>impuestos</b> al ingresar al país, sin importar su valor.',
    ],
   },
   {
    title: '¿Qué son los costos CIF y FOB? ¿En qué influye para la importación de mi vehículo?',
    content: [
     'El <b>costo FOB</b>, por sus siglas en inglés Free on Board, es, solamente, su <b>valor de mercado</b>. Para poder conocerlo deberá buscar en las páginas de compra-venta de vehículos más utilizadas del país de origen de la mudanza.',
     'El costo <b>CIF</b>, por sus siglas en inglés Cost, Insurance and Freight, es la suma del <b>valor de mercado</b> del vehículo, su <b>seguro</b> y el costo del <b>flete marítimo</b>',
     'Es necesario que usted conozca estos valores y su significado ya que serán de <b>suma importancia</b> a la hora de establecer si su vehículo pagará o no <b>impuestos</b> al ingresar al país.',
    ],
   },
]