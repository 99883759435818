import { createSlice } from "@reduxjs/toolkit";

const steperSlice = createSlice({
  name: "steper",
  initialState: {
    step: 'contact',
  },
  reducers: {
    chooseStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const steperReducer = steperSlice.reducer;

export const { chooseStep } = steperSlice.actions;