import styled from "styled-components";
import { Navbar, RespNavbar } from "../";
import React from "react";
import { BarsMenu } from "../barsMenu/BarsMenu";
import { Link } from "react-router-dom";

const StyledHeader = styled.header`
  height: 120px;
  background-color: var(--pearlGrey);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6.2%;

  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  @media (max-width: 576px) {
    padding: 0% 4%;
  }
`;

const HeaderLogo = styled.img`
  height: 100%;
  /* @media (max-width: 576px) {
    height: 65%;
  } */
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  @media (max-width: 576px) {
    height: 65%;
  }
`;

export const Header = () => {
  return (
    <>
      <StyledHeader>
        <HeaderLink to="/">
          <HeaderLogo
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672324781/Logo/logo1_Mesa_de_trabajo_1_cs2tqt.png"
            alt="logo"
          />
        </HeaderLink>
        <BarsMenu color="var(--grafGrey)" />
      </StyledHeader>
      <Navbar />
      <RespNavbar />
    </>
  );
};
