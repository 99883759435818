import { GlobalStyles } from "./styles/GlobalStyles";
import { Layout } from "./components";
import Routes from "./routes/Routes";

export function App() {
  return (
    <>
      <Layout>
        <Routes />
      </Layout>
      <GlobalStyles />
    </>
  );
}

export default App;
