import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Campo requerido")
    .uppercase()
    .trim()
    .transform((str) => str.replace(/ {2,}/g, " ")),
  phone: Yup.string()
    .required("Campo requerido")
    .uppercase()
    .trim()
    .transform((str) => str.replace(/ {2,}/g, " ")),
  email: Yup.string()
    .required("Campo requerido")
    .email("Email invalido")
    .lowercase(),
  msg: Yup.string().required("Campo requerido").lowercase().trim(),
});

export const schemaBudget = Yup.object().shape({
  name: Yup.string()
    .required("Campo requerido")
    .uppercase()
    .trim()
    .transform((str) => str.replace(/ {2,}/g, " ")),
  phone: Yup.string()
    .uppercase()
    .trim()
    .transform((str) => str.replace(/ {2,}/g, " ")),
  mail: Yup.string()
    .required("Campo requerido")
    .email("Email invalido")
    .lowercase(),
  // aspen: Yup.string().required("Campo requerido").lowercase().trim(),
  // diplo: Yup.string().required("Campo requerido").lowercase().trim(),
});

export const schemaDetails = Yup.object().shape({
  date: Yup.string().test('date-or-flexDate', 'Debe completar al menos una fecha', function (value) {
    const flexDateValue = this.parent.flexDate;
    if (!value && !flexDateValue) {
      return this.createError({
        message: 'Debe completar al menos una fecha',
        path: 'date',
      });
    }
    return true;
  }),
  flexDate: Yup.string().test('date-or-flexDate', 'Debe completar al menos una fecha', function (value) {
    const dateValue = this.parent.date;
    if (!value && !dateValue) {
      return this.createError({
        message: 'Debe completar al menos una fecha',
        path: 'flexDate',
      });
    }
    return true;
  }),
  country: Yup.string().required("Campo requerido").lowercase().trim(),
  toCountry: Yup.string().required("Campo requerido").lowercase().trim(),
  city: Yup.string().required("Campo requerido").lowercase().trim(),
  toCity: Yup.string().required("Campo requerido").lowercase().trim(),
});

export const schemaVolumes = Yup.object().shape({
  transportTypes: Yup.string().required("Campo requerido").lowercase().trim(),
});

