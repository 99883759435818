import React from "react";
import { Image, Paragraph, TextSpan, Title } from "../../components";
import styled from "styled-components";
import { InfoContainer } from "../company/Company";

const AlliancesContainer = styled.div`
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  gap: 50px;
  margin: 0 auto;
`;

export const SingleSegment = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;
  text-align: left;
  flex-direction: ${(props) => props.direction};
  padding: 40px 0px 70px 0px;
  border-bottom: 1px solid var(--pearlGrey);
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    gap: 70px;
    max-width: 500px;
  }
`;

export const AlternateInfoContainer = styled(InfoContainer)`
  width: min(90%, 800px);

  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
    max-width: 500px;
    align-items: flex-start;
  }
`;

export const Alliances = () => {
  return (
    <>
      <AlliancesContainer>
        <AlternateInfoContainer>
          <SectionTitleContainer>
            <Title
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="30px"
              padding="0px 0px 10px 0px"
            >
              Alianzas y Certificaciones
            </Title>
          </SectionTitleContainer>
          <SingleSegment>
            <Paragraph
              color="var(--marineBlue)"
              size="15px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              <TextSpan color="--marineBlue" weight="800">
                International Association of Movers (IAM)
              </TextSpan>{" "}
              es la asociación comercial más grande de la industria de mudanzas
              y forwarders. Con más de 2000 miembros, comprende empresas que
              brindan servicios de mudanza, forwarding, envíos, logística y
              servicios relacionados en más de 170 países. Desde 1962, IAM ha
              estado promoviendo el crecimiento y el éxito de sus miembros al
              ofrecer programas, recursos y oportunidades de creación de redes
              sin precedentes para mejorar los negocios y las marcas de sus
              miembros.
            </Paragraph>
            <Image
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222093/Alianzas/IAM_ar3vig-removebg-preview_fybzap.png"
              alt="International Association of Movers"
              height="130px"
            />
          </SingleSegment>
          <SingleSegment direction="row-reverse">
            <Paragraph
              color="var(--marineBlue)"
              size="15px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              <TextSpan color="--marineBlue" weight="800">
                Asian Relocation Association (ARA)
              </TextSpan>{" "}
              es una asociación con miembros principalmente de países asiáticos,
              pero también cuenta con miembros en el extranjero de varios países
              no asiáticos.
              <br /> A todos estos miembros se les permitió ingresar a ARA
              después de una investigación a fondo sobre su enfoque comercial,
              posición en el mercado, políticas de pago y, sobre todo,
              mentalidad de la empresa. Este proceso aún se aplica a cada nueva
              solicitud que recibimos y solo los solicitantes de confianza
              podrán ingresar a ARA.
            </Paragraph>
            <Image
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222092/Alianzas/ARA_qve0rl-removebg-preview_jtllan.png"
              alt="Asian Relocation Association"
              height="150px"
            />
          </SingleSegment>
          <SingleSegment>
            <Paragraph
              color="var(--marineBlue)"
              size="15px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              <TextSpan color="--marineBlue" weight="800">
                International Mobility Alliance (IMA)
              </TextSpan>{" "}
              es una red independiente formada por profesionales de la logística
              alrededor del mundo, unidos por una sólida colaboración con las
              mejores asociaciones de la industria.
            </Paragraph>
            <Image
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222094/Alianzas/IMA_nx07yb-removebg-preview_pnheoj.png"
              alt="International Mobility Alliance"
              height="150px"
            />
          </SingleSegment>
          <SingleSegment direction="row-reverse">
            <Paragraph
              color="var(--marineBlue)"
              size="15px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              <TextSpan color="--marineBlue" weight="800">
                MoversPOE
              </TextSpan>{" "}
              es una asociación internacional de empresas de logística que
              conecta a sus miembros alrededor del mundo para mejorar la calidad
              y optimizar el flujo de trabajo entre ellos.
            </Paragraph>
            <Image
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673221432/Alianzas/MoversPOE_ivn9nk_qrgevd.png"
              alt="Movers POE"
              height="140px"
            />
          </SingleSegment>
          <SingleSegment direction="row">
            <Paragraph
              color="var(--marineBlue)"
              size="15px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              <TextSpan color="--marineBlue" weight="800">
                Eurovan
              </TextSpan>{" "}
                es una organización de compañías lideres e independientes en el mercado europeo de
                las mudanzas internacionales. Con sede en Alemania, conecta a la comunidad de profesionales
                de la logística a lo largo y a lo ancho del continente, además de contar con miembros de
                confianza de todo el mundo que trabajan a diario con países europeos.
            </Paragraph>
            <Image
              src="https://res.cloudinary.com/dvxeh973m/image/upload/v1680707873/Alianzas/Logo_Eurovan_gllavp.png"
              alt="Eurovan"
              height="180px"
            />
          </SingleSegment>
        </AlternateInfoContainer>
      </AlliancesContainer>
    </>
  );
};
