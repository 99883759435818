import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CarsFromArg, CarsToArg, FaqsFromArg, FaqsToArg } from '../../data'

export const StyledAccordion = styled(Accordion)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: ${props => props.pt ? '60px' : ''};
  padding-bottom: ${props => props.pb ? '100px' : ''};
  @media (max-width: 570px) {
    gap: 20px;
  }
`

const StyledAccordionItem = styled(AccordionItem)`
  width: 60%;
  background-color: var(--offWhite);
  border-radius: 10px;
  text-align: left;
  padding: 10px;

  @media (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: 570px) {
    width: 90%;
  }
`

const StyledAccordionButton = styled(AccordionButton)`
  background-color: var(--offWhite);
  color: var(--marineBlue);
  font-family: 'Manrope-bold';
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
  padding: 10px 3%;
  font-weight: bold;

  @media (max-width: 570px) {
    font-size: 1rem;
  }
`


const StyledAccordionIcon = styled(AccordionIcon)`
  font-size: 2rem;

  @media (max-width: 570px) {
    font-size: 1.5rem;
  }
`

const StyledAccordionPanel = styled(AccordionPanel)`
  font-family: 'Manrope-regular';
  color: var(--marineBlue);
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 10px 3%;
  padding-right: 8%;

  @media (max-width: 570px) {
    font-size: 0.9rem;
  }
`

const StyledBox = styled.p`
  margin-top: 5px;
`
// const StyledSpan = styled.p`
  
// `

const FaqsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 75%;
  padding: 5% 0px;
  margin: 0 auto;
`

const FaqsTitle = styled.h2`
  font-family: 'Manrope-bold';
  font-size: 2.5rem;
  color: var(--marineBlue);

  @media (max-width: 570px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1.6rem;
  }
`

const FAQsId = () => {

  const { faq } = useParams();
  const navigate = useNavigate();
  const [accordionIndex, setAccordionIndex] = useState(null);
  const [accordionCarIndex, setAccordionCarIndex] = useState(null);

  useEffect(() => {
    if(!(faq === 'hacia-argentina' || faq === 'desde-argentina')){
      navigate('/preguntas-frecuentes');
    }
  }, [faq, navigate]);


  const handleClick = (parameter) => {
    if (parameter === accordionIndex) {
      setAccordionIndex(-1)
      return;
    }
    setAccordionIndex(parameter);
  };
  const handleClickCarAccordeon = (parameter) => {
    if (parameter === accordionCarIndex) {
      setAccordionCarIndex(-1)
      return;
    }
    setAccordionCarIndex(parameter);
  };

  return (
    <>
      <FaqsContainer>
        <FaqsTitle>Preguntas frecuentes { faq === 'hacia-argentina' ? 'hacia Argentina' : 'desde Argentina' }</FaqsTitle>
      </FaqsContainer>
      <StyledAccordion allowToggle index={ accordionIndex }>
        {/* {FaqsFromArg.map((item, index) => { */}
        {(faq === 'hacia-argentina' 
          ? FaqsToArg
          : faq === 'desde-argentina'
          ? FaqsFromArg
          : [])
          .map((item, index) => {
          return (
            <StyledAccordionItem key={index}>
              <h2>
                <StyledAccordionButton onClick={() => handleClick(index)}>
                  <Box flex='1' textAlign='left'>
                    { item.title }
                  </Box>
                  <StyledAccordionIcon />
                </StyledAccordionButton>
              </h2>
              <StyledAccordionPanel>
                { item.content.map( (item, index) => {
                  if(item.includes('***')){
                    const arr = item.split('***');
                    const element = arr.find(e => e.length < 3)
                    const i = arr.indexOf(element);
                    arr.splice(i, 1);
                    return (
                      <StyledBox key={index}>
                        {arr.map((item, index) => {
                          if(index === i){
                            return (
                              <span onClick={() => handleClick(Number(element))} key={index} style={{textDecoration: 'underline', cursor: 'pointer'}}>{item}</span>
                            )
                          }
                          return (
                            <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
                          );
                        })}
                      </StyledBox>
                  )
                  }
                  return (
                      <StyledBox key={index} dangerouslySetInnerHTML={{ __html: item }} />
                  )
                }) }
              </StyledAccordionPanel>
            </StyledAccordionItem>
          )
        })}
      </StyledAccordion>
      <FaqsContainer>
        <FaqsTitle>{ faq === 'hacia-argentina' ? 'Importación de vehículos a Argentina' : 'Exportación de vehículos desde Argentina' }</FaqsTitle>
      </FaqsContainer>
      <StyledAccordion pb allowToggle index={ accordionCarIndex }>
        {/* {FaqsFromArg.map((item, index) => { */}
        {(faq === 'hacia-argentina' 
          ? CarsToArg
          : faq === 'desde-argentina'
          ? CarsFromArg
          : [])
          .map((item, index) => {
          return (
            <StyledAccordionItem key={index}>
              <h2>
                <StyledAccordionButton onClick={() => handleClickCarAccordeon(index)}>
                  <Box flex='1' textAlign='left'>
                    { item.title }
                  </Box>
                  <StyledAccordionIcon />
                </StyledAccordionButton>
              </h2>
              <StyledAccordionPanel>
                { item.content.map( (item, index) => {
                  if(item.includes('***')){
                    const arr = item.split('***');
                    const element = arr.find(e => e.length < 3)
                    const i = arr.indexOf(element);
                    arr.splice(i, 1);
                    return (
                      <StyledBox key={index}>
                        {arr.map((item, index) => {
                          if(index === i){
                            return (
                              <span onClick={() => handleClickCarAccordeon(Number(element))} key={index} style={{textDecoration: 'underline', cursor: 'pointer'}}>{item}</span>
                            )
                          }
                          return (
                            <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
                          );
                        })}
                      </StyledBox>
                  )
                  }
                  return (
                      <StyledBox key={index} dangerouslySetInnerHTML={{ __html: item }} />
                  )
                }) }
              </StyledAccordionPanel>
            </StyledAccordionItem>
          )
        })}
      </StyledAccordion>
    </>
  )
}

export default FAQsId