import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { Input, Paragraph, Secondarytitle, Steper, Title } from "../../components";
import { schemaBudget } from "../../config";
import { chooseContact } from "../../store/rootSlice/rootSlice";
import { chooseStep } from "../../store/steperSlice/SteperSlice";
import {
  ButtonContainer,
  InputRowContainer,
  StyledButton,
  StyledForm,
} from "./Step2";

const IdiomaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  padding-bottom: 20px;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 680px) {
    width: 90%;
  }
`;

export const Budget = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contacto = useSelector((state) => state.root.contacto);
  const state = useSelector((state) => state);
  const [isValidForm, setIsValidForm] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    // reset,
    setValue,
  } = useForm({
    defaultValues: {
      contacto,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schemaBudget),
  });

  const onSubmit = (data) => {
    const { name, mail, aspen, phone, diplo } = data;
    let templateParams = {
      name: name,
      email: mail,
      phone: phone,
      aspen: aspen,
      diplomaticFranchise: diplo,
    };
    dispatch(chooseContact(templateParams));
    navigate(`/presupuesto/${id}/detalles-de-la-mudanza`);
  };

  useEffect(() => {
    let interval;
    interval = setTimeout(() => {
      setIsValidForm(null);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isValidForm]);

  useEffect(() => {
    if (id !== "diplomaticos" && id !== "particulares-locales" && id !== "particulares-internacionales") {
      navigate("/");
    }
    dispatch(chooseStep('contact'))
    const { name, email, phone, aspen, diplomaticFranchise } = state.root.contact;
    setValue("name", name);
    setValue("mail", email);
    setValue("phone", phone);
    setValue("aspen", aspen);
    setValue("diplo", diplomaticFranchise);
  }, [id, navigate, state, setValue, dispatch]);

  return (
    <>
      <StyledForm padding='100px 0px 0px 0px' onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer>
          <Title
            color="var(--marineBlue)"
            size="26px"
            family="Manrope-semiBold"
          >
            {" "}
            Presupuesto online para{" "}
            <span style={{ color: "var(--orange)" }}> {id === 'diplomaticos' ? 'diplomáticos' : 'particulares'} </span>{" "}
          </Title>
          <Secondarytitle
            color="var(--marineBlue)"
            size="14px"
            family="Manrope-regular"
          >
            {" "}
            Complete el formulario para recibir el presupuesto online.
          </Secondarytitle>
        </TitleContainer>

        <Steper />

        <Input
          id="name"
          name="name"
          width="60%"
          widthMil="70%"
          type="text"
          placeholder=""
          label="Nombre y Apellido"
          height="80px"
          border="2px solid var(--pearlGrey)"
          borderRadius="10px"
          outline="2px solid var(--marineBlue)"
          placeholderColor="#999999ce"
          placeholderFont="Manrope-medium"
          fontSize="19px"
          control={control}
          error={errors.name}
          transform={ control._getWatch('name', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
        />
        <Input
          id="mail"
          name="mail"
          width="60%"
          widthMil="70%"
          type="text"
          placeholder=""
          label="E-mail"
          height="80px"
          border="2px solid var(--pearlGrey)"
          borderRadius="10px"
          outline="2px solid var(--marineBlue)"
          placeholderColor="#999999ce"
          placeholderFont="Manrope-medium"
          fontSize="19px"
          control={control}
          error={errors.mail}
          transform={ control._getWatch('mail', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
        />
        <Input
          id="phone"
          name="phone"
          width="60%"
          widthMil="70%"
          type="text"
          // placeholder="Teléfono (opcional)"
          label="Teléfono (opcional)"
          height="80px"
          border="2px solid var(--pearlGrey)"
          borderRadius="10px"
          outline="2px solid var(--marineBlue)"
          placeholderColor="#999999ce"
          placeholderFont="Manrope-medium"
          fontSize="19px"
          control={control}
          error={errors.phone}
          transform={ control._getWatch('phone', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
        />
        {id === 'diplomaticos' && (<InputRowContainer>
          <Input
            id="aspen"
            name="aspen"
            width="50%"
            type="text"
            placeholder=""
            label="¿Está afiliado a APSEN?"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.aspen}
            transform={ control._getWatch('aspen', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
          <Input
            id="diplo"
            name="diplo"
            width="50%"
            type="text"
            placeholder=""
            label="¿Tiene Franquicia diplomática?"
            height="80px"
            border="2px solid var(--pearlGrey)"
            borderRadius="10px"
            outline="2px solid var(--marineBlue)"
            placeholderColor="#999999ce"
            placeholderFont="Manrope-medium"
            fontSize="19px"
            control={control}
            error={errors.diplo}
            transform={ control._getWatch('diplo', '', { nest: true }) === '' ? '' : 'translate(0, -23px) scale(0.6)' }
          />
        </InputRowContainer>)}
        <ButtonContainer column> 
          <IdiomaContainer>
            <Paragraph color="#999999ce" size="19px" family="Manrope-medium">
              {" "}
              Idioma del formulario (seleccionar){" "}
            </Paragraph>
            <InputRowContainer idioma>
              <Input
                id="ES"
                name="ES"
                type="checkbox"
                label="Español"
                position="static"
                width="25px"
                height="25px"
                border="2px solid var(--pearlGrey)"
                borderRadius="10px"
                outline="none"
                placeholderColor="#999999ce"
                placeholderFont="Manrope-medium"
                fontSize="19px"
                flexDirection="row"
                paddingLeft="0px"
                noTransform
                labelWidth="100px"
                control={control}
              />
              <Input
                id="EN"
                name="EN"
                type="checkbox"
                position="static"
                label="Inglés"
                width="25px"
                height="25px"
                labelWidth="100px"
                border="2px solid var(--pearlGrey)"
                borderRadius="10px"
                outline="none"
                placeholderColor="#999999ce"
                placeholderFont="Manrope-medium"
                fontSize="19px"
                flexDirection="row"
                paddingLeft="0px"
                noTransform
                control={control}
              />
            </InputRowContainer>
          </IdiomaContainer>
          <StyledButton type="submit">SIGUIENTE</StyledButton>
        </ButtonContainer>
      </StyledForm>
    </>
  );
};
