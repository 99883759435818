import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const FaqsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10%;
  gap: 50px;
  width: 75%;
  margin: 0 auto;
  @media (max-width: 1100px) {
    gap: 30px;
  }
`

const FaqsTitle = styled.h2`
  font-family: 'Manrope-bold';
  font-size: 2.5rem;
  color: var(--marineBlue);
  @media (max-width: 510px) {
    font-size: 2rem;
  }
`

const FaqsSubtitle = styled.h3`
  font-family: 'Manrope-extraLight';
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--marineBlue);
  @media (max-width: 510px) {
    font-size: 1.2rem;
  }
`

const FaqsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  padding-bottom: 10%;
  @media (max-width: 510px) {
    gap: 30px;
  }
`

const FaqsButton = styled.button`
  font-family: 'Manrope-regular';
  background-color: ${props => props.selected ? 'var(--orange)' : 'var(--offWhite)'};
  color: ${props => props.selected ? 'var(--offWhite)' : 'var(--orange)'};
  font-size: 2.3rem;
  border: none;
  border-radius: 5px;
  padding: 20px 100px;

  &:hover {
    cursor: pointer;
    background-color: var(--orange);
    color: var(--offWhite);
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 1100px) {
    font-size: 1.5rem;
    padding: 0px;
    width: 450px;
    height: 80px;
  }
  @media (max-width: 560px) {
    font-size: 1.3rem;
    width: 300px;
    height: 60px;
  }
`

export const Presupuesto = () => {

  const navigate = useNavigate();

  return (
    <div>
      <FaqsContainer>
        <FaqsTitle>Presupuesto online</FaqsTitle>
        <FaqsSubtitle>Elegí la opción correspondiente.</FaqsSubtitle>
        <FaqsButtonContainer>
          <FaqsButton
            onClick={() => navigate('/presupuesto/particulares-internacionales')}
          >
            Particulares
          </FaqsButton>
          <FaqsButton
            onClick={() => navigate('/presupuesto/diplomaticos')}
          >
            Diplomáticos
          </FaqsButton>
        </FaqsButtonContainer>
      </FaqsContainer>
    </div>
  )
}
