import { useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { HashRouter, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Footer, Header, MobileFooter, TabletFooter } from "../";

import { store } from "../../store/store";

const HeaderPlaceholder = styled.div`
  height: 120px;
  width: 100%;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
`;

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export const Layout = ({ children }) => {
  return (
    <>
      <HashRouter>
        <Provider store={store}>
          <Wrapper>
            <Header />
            <HeaderPlaceholder />
            <div>{children}</div>
            {window.innerWidth > 992 ? (
              <Footer />
            ) : window.innerWidth > 576 ? (
              <TabletFooter />
            ) : (
              <MobileFooter />
            )}
          </Wrapper>
        </Provider>
      </HashRouter>
    </>
  );
};
