import React from "react";
import styled from "styled-components";
// import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { TextSpan } from "../";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  width: 100%;
  background-color: var(--grafGrey);
  padding: 80px 0px 50px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    padding: 80px 0px;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 120px;
  @media (max-width: 992px) {
    gap: 10%;
  }
  @media (max-width: 768px) {
    gap: 5%;
    padding: 0px 20px;
  }

  @media (max-width: 576px) {
    gap: 30px;
    padding: 0px 0px;
    align-items: center;
    margin-left: 0px;
    width: 90%;
    flex-direction: column;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  //gap as prop
  gap: ${(props) => props.gap || "0px"};

  @media (max-width: 576px) {
    width: 100%;
    align-items: center;
  }
`;

const FooterTitle = styled.h3`
  color: var(--offWhite);
  font-family: "Manrope-extraLight";
  letter-spacing: 1px;
  font-size: 22px;
`;

// const FooterMediaContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   margin-left: -5px;
//   svg {
//     color: var(--footerGrey);
//     font-size: 25px;
//   }
// `;

const FooterLogo = styled.img`
  height: 85px;
  margin-top: 10px;
  margin-left: -5px;
  @media (max-width: 992px) {
    margin-top: 0px;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const FooterFaqs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: flex-end;
  margin-right: 50px;
  @media (max-width: 992px) {
    margin-right: 0;
    align-self: flex-start;
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    width: 100%;
    align-items: center;
  }
`;

const FooterCopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  @media (max-width: 576px) {
    width: 90%;
  }
`;

const FooterParagraph = styled.p`
  //font-size as prop
  font-size: ${(props) => props.fontSize || "16px"};
  font-family: "Manrope-regular";
  color: var(--footerGrey);
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 400px) {
    width: 100%;
    align-items: center;
  }
`;

const FooterLink = styled.a`
  color: var(--footerGrey);
  display: flex;
  gap: 4px;
`;

const FooterInternalLink = styled(Link)`
  color: var(--footerGrey);
  display: flex;
  gap: 4px;
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <StyledFooter>
        <FooterColumn gap="20px" mt="10px">
          {/* <FooterTitle>Social</FooterTitle>
          <FooterMediaContainer>
            <FooterLink href="#">
              <FaFacebookF />
            </FooterLink>
            <FooterLink href="#">
              <FaInstagram />
            </FooterLink>
            <FooterLink href="https://wa.me/541135847805?text=Hola,%20quería%20hacerles%20una%20consulta">
              <FaWhatsapp />
            </FooterLink>
          </FooterMediaContainer> */}
          <FooterLogo src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222566/Logo/logo_mundo-02_kiybm2_rtwjot.png" />
        </FooterColumn>
        <FooterColumn gap="50px">
          <FooterFaqs>
            <FooterTitle>FAQ</FooterTitle>
            <FooterParagraph>
              <FooterInternalLink to="/preguntas-frecuentes">
                <TextSpan color="var(--orange)">Preguntas frecuentes</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
            <FooterParagraph>
              <FooterInternalLink to="/la-compania">
                <TextSpan color="var(--footerGrey)">Sobre nosotros</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
          </FooterFaqs>
          <FooterCopyrightContainer>
            <FooterParagraph fontSize="14px">
              © 2023 Elishar empresa de mudanzas. All Rights Reserved.
            </FooterParagraph>
            <FooterParagraph fontSize="12px">
              Desarrollado por Alma digital studio.
            </FooterParagraph>
          </FooterCopyrightContainer>
        </FooterColumn>
        <FooterColumn gap="20px">
          <FooterTitle>Contacto</FooterTitle>
          <ContactInfo>
            <FooterParagraph>Tel: +54 11 3584-7805</FooterParagraph>
            <FooterParagraph>
              <FooterLink
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@elishar.com.ar&su=Consulta%20por%20mudanza"
                target="_blank"
              >
                Mail:
                <TextSpan color="var(--orange)">info@elishar.com.ar</TextSpan>
              </FooterLink>
            </FooterParagraph>
          </ContactInfo>
          <ContactInfo>
            <FooterParagraph>Grito de Asencio 3535, C.A.B.A</FooterParagraph>
            <FooterParagraph>Bs. As, Argentina</FooterParagraph>
            <FooterLink
              target="_blank"
              href="https://www.google.com/maps/place/Grito+de+Asencio+3535,+C1437IMI+CABA/@-34.6475346,-58.4134293,17z/data=!3m1!4b1!4m5!3m4!1s0x95bccb7364613cdd:0xdaa71252488926da!8m2!3d-34.6475346!4d-58.4112406"
            >
              <TextSpan color="var(--orange)">Ver mapa</TextSpan>
            </FooterLink>
          </ContactInfo>
        </FooterColumn>
      </StyledFooter>
    </FooterContainer>
  );
};

export const TabletFooter = () => {
  return (
    <FooterContainer>
      <StyledFooter>
        <FooterColumn gap="20px">
          {/* <FooterTitle>Social</FooterTitle>
          <FooterMediaContainer>
            <FooterLink href="#">
              <FaFacebookF />
            </FooterLink>
            <FooterLink href="#">
              <FaInstagram />
            </FooterLink>
            <FooterLink href="#">
              <FaWhatsapp />
            </FooterLink>
          </FooterMediaContainer> */}
          <FooterFaqs>
            <FooterTitle>FAQ</FooterTitle>
            <FooterParagraph>
              <FooterInternalLink to="/preguntas-frecuentes">
                <TextSpan color="var(--orange)">Preguntas frecuentes</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
            <FooterParagraph>
              <FooterInternalLink to="/la-compania">
                <TextSpan color="var(--footerGrey)">Sobre nosotros</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
          </FooterFaqs>
        </FooterColumn>
        <FooterColumn gap="20px">
          <FooterTitle>Contacto</FooterTitle>
          <ContactInfo>
            <FooterParagraph>Tel: +54 11 3584-7805</FooterParagraph>
            <FooterParagraph>
              <FooterLink
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@elishar.com.ar&su=Consulta%20por%20mudanza"
                target="_blank"
              >
                Mail:
                <TextSpan color="var(--orange)">info@elishar.com.ar</TextSpan>
              </FooterLink>
            </FooterParagraph>
          </ContactInfo>
          <ContactInfo>
            <FooterParagraph>Grito de Asencio 3535, C.A.B.A</FooterParagraph>
            <FooterParagraph>Bs. As, Argentina</FooterParagraph>
            <FooterLink
              target="_blank"
              href="https://www.google.com/maps/place/Grito+de+Asencio+3535,+C1437IMI+CABA/@-34.6475346,-58.4134293,17z/data=!3m1!4b1!4m5!3m4!1s0x95bccb7364613cdd:0xdaa71252488926da!8m2!3d-34.6475346!4d-58.4112406"
            >
              {/* <TextSpan family="Manrope-regular" color="var(--orange)">
                Ver mapa
              </TextSpan> */}
            </FooterLink>
          </ContactInfo>
        </FooterColumn>
        <FooterLogo src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222566/Logo/logo_mundo-02_kiybm2_rtwjot.png" />
      </StyledFooter>
      <FooterCopyrightContainer>
        <FooterParagraph fontSize="14px">
          © 2023 Elishar empresa de mudanzas. All Rights Reserved.
        </FooterParagraph>
        <FooterParagraph fontSize="12px">
          Desarrollado por Alma digital studio.
        </FooterParagraph>
      </FooterCopyrightContainer>
    </FooterContainer>
  );
};

export const MobileFooter = () => {
  return (
    <FooterContainer>
      <FooterLogo src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222566/Logo/logo_mundo-02_kiybm2_rtwjot.png" />
      <StyledFooter>
        <FooterColumn gap="20px">
          {/* <FooterTitle>Social</FooterTitle>
          <FooterMediaContainer>
            <FooterLink href="#">
              <FaFacebookF />
            </FooterLink>
            <FooterLink href="#">
              <FaInstagram />
            </FooterLink>
            <FooterLink href="#">
              <FaWhatsapp />
            </FooterLink>
          </FooterMediaContainer> */}
          <FooterFaqs>
            <FooterTitle>FAQ</FooterTitle>
            <FooterParagraph>
              <FooterInternalLink to="/preguntas-frecuentes">
                <TextSpan color="var(--orange)">Preguntas frecuentes</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
            <FooterParagraph>
              <FooterInternalLink to="/la-compania">
                <TextSpan color="var(--footerGrey)">Sobre nosotros</TextSpan>
              </FooterInternalLink>
            </FooterParagraph>
          </FooterFaqs>
        </FooterColumn>
        <FooterColumn gap="20px">
          <FooterTitle>Contacto</FooterTitle>
          <ContactInfo>
            <FooterParagraph>Tel: +54 11 3584-7805</FooterParagraph>
            <FooterParagraph>
              <FooterLink
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@elishar.com.ar&su=Consulta%20por%20mudanza"
                target="_blank"
              >
                Mail:
                <TextSpan color="var(--orange)">info@elishar.com.ar</TextSpan>
              </FooterLink>
            </FooterParagraph>
          </ContactInfo>
          <ContactInfo>
            <FooterParagraph>Grito de Asencio 3535, C.A.B.A</FooterParagraph>
            <FooterParagraph>Bs. As, Argentina</FooterParagraph>
            <FooterLink
              target="_blank"
              href="https://www.google.com/maps/place/Grito+de+Asencio+3535,+C1437IMI+CABA/@-34.6475346,-58.4134293,17z/data=!3m1!4b1!4m5!3m4!1s0x95bccb7364613cdd:0xdaa71252488926da!8m2!3d-34.6475346!4d-58.4112406"
            >
              {/* <TextSpan family="Manrope-regular" color="var(--orange)">
                Ver mapa
              </TextSpan> */}
            </FooterLink>
          </ContactInfo>
        </FooterColumn>
      </StyledFooter>
      <FooterCopyrightContainer>
        <FooterParagraph fontSize="14px">
          © 2023 Elishar empresa de mudanzas. All Rights Reserved.
        </FooterParagraph>
        <FooterParagraph fontSize="12px">
          Desarrollado por Alma digital studio.
        </FooterParagraph>
      </FooterCopyrightContainer>
    </FooterContainer>
  );
};
