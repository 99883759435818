import { createSlice } from "@reduxjs/toolkit";

export const transportTypes = {
  fullHome: "Toda la vivienda",
  partialHome: "Parte de la casa",
  someItems: "Algunos muebles",
  someBoxes: "Algunas cajas o maletas",
};

const rootSlice = createSlice({
  name: "root",
  initialState: {
    id: "",
    contact: {
      name: "",
      email: "",
      phone: "",
      aspen: "",
      diplomaticFranchise: "",
    },
    details: {
      date: "",
      flexDate: "",
      from: {
        country: "",
        city: "",
        address: "",
        zipCode: "",
        house: false,
        apartment: false,
        other: "",
      },
      to: {
        country: "",
        city: "",
        address: "",
        zipCode: "",
        house: false,
        apartment: false,
        other: "",
      },
    },
    volumes: {
      transportTypes: "",
      description: "",
    },
    volumesCalculator: null,
  },
  reducers: {
    chooseContact: (state, action) => {
      state.contact = action.payload;
    },
    chooseDetails: (state, action) => {
      state.details = action.payload;
    },
    chooseVolumes: (state, action) => {
      state.volumes = action.payload;
    },
    chooseVolumesCalculator: (state, action) => {
      state.volumesCalculator = action.payload;
    },
    chooseID: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const rootReducer = rootSlice.reducer;

export const {
  chooseContact,
  chooseDetails,
  chooseVolumes,
  chooseVolumesCalculator,
  chooseID,
} = rootSlice.actions;
