import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { toggleAccordion } from "../../store/accordionSlice/accordionSlice";
import { toggleMenu } from "../../store/menuSlice/menuSlice";

const StyledRespNavbar = styled.div`
  position: fixed;
  top: 0;
  height: calc(100vh - 120px);
  margin-top: 120px;
  width: 100%;
  right: ${(props) => (props.clicked ? "0" : "-100%")};
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 99;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  background-color: var(--grafGrey);
  position: fixed;
  transition: all 0.3s ease-in-out;
  top: 0;
  z-index: 2;
  left: ${(props) => (props.clicked ? "0" : "-100%")};
  opacity: 0.4;
`;

const StyledAccordion = styled(Accordion)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;

const StyledAccordionItem = styled(AccordionItem)`
  width: 40%;
  background-color: var(--grafGrey);
  text-align: left;
  padding: 10px;
  @media (max-width: 830px) {
    width: 60%;
  }
  @media (max-width: 540px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const StyledAccordionButton = styled(AccordionButton)`
  background-color: var(--grafGrey);
  color: var(--offWhite);
  font-family: "Manrope-bold";
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
  padding: 10px 3%;
  font-weight: bold;
`;

const StyledAccordionIcon = styled(AccordionIcon)`
  font-size: 2rem;
`;

const StyledAccordionPanel = styled(AccordionPanel)`
  font-family: "Manrope-regular";
  color: var(--marineBlue);
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 10px 3%;
  padding-right: 8%;
`;

const StyledBox = styled(Link)`
  text-decoration: none;
  color: var(--offWhite);
  display: block;
  margin-top: 5px;
`;
const NavbarButton = styled(Link)`
  background-color: var(--orange);
  color: var(--offWhite);
  padding: 14px 2%;
  padding-right: 8%;
  /* padding: 10px 50px; */
  width: 40%;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-family: "Manrope-bold";
  cursor: pointer;
  border: 1px solid var(--orange);
  text-align: left;
  @media (max-width: 830px) {
    width: 60%;
  }
  @media (max-width: 540px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const RespNavbar = () => {
  const { isMenuOpen } = useSelector((state) => state.menu);
  // prevent body scroll when menu is open

  const { accordionIndex } = useSelector((state) => state.accordion);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        dispatch(toggleMenu()); // acción para cerrar el menú
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen, dispatch]);

  const handleClick = (parameter) => {
    if (parameter === accordionIndex.payload) {
      dispatch(toggleAccordion(-1));
      return;
    }
    dispatch(toggleAccordion(parameter));
  };

  const handleLinkClick = () => {
    dispatch(toggleMenu());
  };

  return (
    <>
      <Overlay clicked={isMenuOpen} />
      <StyledRespNavbar clicked={isMenuOpen}>
        <StyledAccordion allowToggle index={accordionIndex.payload}>
          <StyledAccordionItem>
            <StyledAccordionButton onClick={() => handleClick(0)}>
              <Box flex="1" textAlign="left">
                Servicios para embajadas
              </Box>
              <StyledAccordionIcon />
            </StyledAccordionButton>
            <StyledAccordionPanel pb={4}>
              <StyledBox
                onClick={handleLinkClick}
                to="/presupuesto/diplomaticos"
              >
                Presupuesto Online
              </StyledBox>
            </StyledAccordionPanel>
          </StyledAccordionItem>

          <StyledAccordionItem>
            <StyledAccordionButton onClick={() => handleClick(1)}>
              <Box flex="1" textAlign="left">
                Servicios para particulares
              </Box>
              <StyledAccordionIcon />
            </StyledAccordionButton>
            <StyledAccordionPanel pb={4}>
              <StyledBox
                onClick={handleLinkClick}
                to="/presupuesto/particulares-locales"
              >
                Locales
              </StyledBox>
              <StyledBox
                onClick={handleLinkClick}
                to="/presupuesto/particulares-internacionales"
              >
                Internacionales
              </StyledBox>
            </StyledAccordionPanel>
          </StyledAccordionItem>

          <StyledAccordionItem>
            <StyledAccordionButton onClick={() => handleClick(2)}>
              <Box flex="1" textAlign="left">
                Conocenos
              </Box>
              <StyledAccordionIcon />
            </StyledAccordionButton>
            <StyledAccordionPanel pb={4}>
              <StyledBox onClick={handleLinkClick} to="/la-compania">
                Compañia
              </StyledBox>
              <StyledBox onClick={handleLinkClick} to="/alianzas">
                Alianzas
              </StyledBox>
              <StyledBox onClick={handleLinkClick} to="/materiales">
                Materiales
              </StyledBox>
              <StyledBox onClick={handleLinkClick} to="/contacto">
                Contacto
              </StyledBox>
              <StyledBox onClick={handleLinkClick} to="/requisitos">
                Requisitos
              </StyledBox>
              <StyledBox onClick={handleLinkClick} to="/preguntas-frecuentes">
                FAQs
              </StyledBox>
            </StyledAccordionPanel>
          </StyledAccordionItem>
          <NavbarButton onClick={handleLinkClick} to="/presupuesto">
            Presupuesto Online
          </NavbarButton>
        </StyledAccordion>
      </StyledRespNavbar>
    </>
  );
};
