import React from "react";
import styled from "styled-components";
import { Image } from "..";

export const AlliancesContainer = styled.div`
  max-width: 1400px;
  // width as prop
  width: ${(props) => props.width};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  margin: 15px 0px;
  @media (max-width: 992px) {
    /* flex-direction: column; */
    align-items: center;
    gap: 90px;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const AlliancesCards = () => {
  return (
    <AlliancesContainer width="90%">
      <Image
        src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222092/Alianzas/ARA_qve0rl-removebg-preview_jtllan.png"
        alt="Asian Relocation Association"
        height="140px"
      />
      <Image
        src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222093/Alianzas/IAM_ar3vig-removebg-preview_fybzap.png"
        alt="International Association of movers"
        height="110px"
      />
      <Image
        src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222094/Alianzas/IMA_nx07yb-removebg-preview_pnheoj.png"
        alt="International Mobility Alliance"
        height="125px"
      />
      <Image
        src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673221432/Alianzas/MoversPOE_ivn9nk_qrgevd.png"
        alt="Movers Poe"
        height="140px"
      />
      <Image
        src="https://res.cloudinary.com/dvxeh973m/image/upload/v1680707873/Alianzas/Logo_Eurovan_gllavp.png"
        alt="Eurovan"
        height="140px"
      />
    </AlliancesContainer>
  );
};
