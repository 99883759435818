import React from "react";
import styled from "styled-components";
import { Button, MainTitle, Paragraph } from "../../../components";

const HeroContainer = styled.section`
  background-color: var(--marineBlue);
  width: 100%;
  height: 100vh;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
    height: unset;
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 7%;
  width: 45%;
  gap: 30px;
  margin: 0 auto;

  @media (min-width: 1950px) {
    padding: 0px 9.5%;
  }
  @media (max-width: 992px) {
    width: auto;
    padding: 80px 20px;
  }
  @media (max-width: 576px) {
    padding: 80px 0px;
    width: 90%;
  }
`;

const HeroImage = styled.div`
  width: 55%;
  flex-grow: 1;
  height: 100%;
  background-image: url("https://res.cloudinary.com/dvxeh973m/image/upload/v1672325048/Banners/mak-8wy9mGgmGoU-unsplash_hm5lrm.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    width: 100%;
    height: 300px;
  }
`;

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  @media (max-width: 992px) {
    max-width: 385px;
  }
`;

export const Hero = () => {
  return (
    <HeroContainer>
      <HeroText>
        <MainTitle size="38px" align="left">
          Llevá tus cosas
          <br />a donde quieras!
        </MainTitle>

        <ParagraphContainer>
          <Paragraph size="14px">
            Lleva tu casa a cualquier parte del mundo.
            {/* <TextSpan color="var(--orange)"> mundo.</TextSpan> */}
          </Paragraph>
          <Paragraph size="14px">
            Deja tu mudanza en
            nuestras manos y nosotros nos encargamos de todo. Contamos con una
            amplia experiencia en el
            rubro desde el año 2002.
            {/* Deja tu <TextSpan color="var(--orange)">mudanza</TextSpan> en
            nuestras manos y nosotros nos encargamos de todo. Contamos con una
            amplia <TextSpan color="var(--orange)">experiencia</TextSpan> en el
            rubro desde el año 2002. */}
          </Paragraph>
        </ParagraphContainer>
        <Button
          to="/presupuesto"
          mt="40px"
          size="15px"
          background="transparent"
          color="var(--orange)"
          border="1px solid var(--orange)"
          family="Manrope-semibold"
          hovercolor="var(--offWhite)"
          hoverbackground="var(--orange)"
        >
          SOLICITÁ TU PRESUPUESTO
        </Button>
      </HeroText>
      <HeroImage />
    </HeroContainer>
  );
};
