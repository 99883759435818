import React from "react";
import styled from "styled-components";
import { AlliancesCards, Title } from "../../../components";

const AlliancesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0px;
  width: 100%;
  background-color: var(--offWhite);
  gap: 10px;
`;

export const Alliances = () => {
  return (
    <AlliancesSection>
      <Title color="var(--marineBlue)" family="Manrope-bold" size="30px">
        Nuestras Alianzas
      </Title>

      <AlliancesCards />
    </AlliancesSection>
  );
};
