import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { mediaQueries } from "../../../config";

const ContactInfoGeneralContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: url(https://res.cloudinary.com/dvxeh973m/image/upload/v1673014067/Banners/banner_foto-02_gao9mo.jpg)
    no-repeat;
  background-size: cover;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const ContactInfoContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 840px) {
    align-items: center;
  }
`;

const ContactInfoTitle = styled.h2`
  font-family: ${({ regular }) =>
    regular ? "Manrope-extralight" : "Manrope-bold"};
  font-size: 2.5rem;
  color: var(--offWhite);
  text-align: center;
`;

const ItemLink = styled(NavLink)`
  width: 60%;
  margin-top: 10%;
  background-color: transparent;
  height: 45px;
  color: var(--offWhite);
  border: 1.4px solid var(--offWhite);
  cursor: pointer;
  font-size: 13px;
  font-size: 1.3rem;
  font-family: "Manrope-regular";
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--marineBlue);
    background-color: var(--offWhite);
  }
`;

export const ContactInfo = () => {
  return (
    <ContactInfoGeneralContainer>
      <ContactInfoContainer>
        <ContactInfoTitle regular>Solicita tu</ContactInfoTitle>
        <ContactInfoTitle>presupuesto online</ContactInfoTitle>
          <ItemLink to="/presupuesto">
              desde aquí
          </ItemLink>
      </ContactInfoContainer>
    </ContactInfoGeneralContainer>
  );
};
