import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Paragraph, TextSpan, Title } from "../../components";

const PresupuestoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10%;
  gap: 30px;
  width: 60%;
  margin: 0 auto;

  @media (max-width: 830px) {
    width: 80%;
  }
  @media (max-width: 620px) {
    width: 90%;
  }
`;

const PresupuestoButton = styled(Link)`
  background-color: var(--marineBlue);
  color: var(--offWhite);
  padding: 15px 60px;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Manrope-bold";
  cursor: pointer;
  border: 1px solid var(--marineBlue);
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: var(--marineBlue);
    transition: all 0.3s ease-in-out;
  }
`;

const PresupuestoImage = styled.div`
  margin-top: 10%;
  width: 100vw;
  flex-grow: 1;
  height: 100vh;
  background-image: url(${(props) => props.bImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    height: 300px;
  }
`;

const StyledTitle = styled(Title)`
  @media (max-width: 550px) {
    font-size: 20px;
    text-align: center;
  }
`

const StyledParagraph = styled(Paragraph)`
  padding: ${(props) => props.padding};
  @media (max-width: 550px) {
    font-size: 16px;
  }
`

export const PresupuestoId = () => {
  const { id } = useParams();
  const url = `/presupuesto/${id}/contacto`;

  return (
    <>
      {id === "diplomaticos" && (
        <PresupuestoContainer>
          <StyledTitle
            color="var(--marineBlue)"
            size="26px"
            family="Manrope-bold"
            spacing="0.5px"
          >
            Bienvenido al{" "}
            <TextSpan color="var(--orange)" weight="800">
              {" "}
              servicio para {id === "diplomaticos" ? "embajadas" : 'particulares'}{" "}
            </TextSpan>{" "}
            de Elishar
          </StyledTitle>
          <StyledParagraph
            spacing="1px"
            color="var(--marineBlue)"
            size="20px"
            family="Manrope-regular"
            textAlign="center"
          >
            El personal diplomático es considerado para nosotros como esencial,
            por tal motivo todo contacto, sea vía correo electrónico, telefónico
            o presencial se hará a través del los titulares de la empresa, a si
            mismo la supervisión de los embalajes de exportación y las entregas
            de importación que tengan como origen o destino la ciudad de Buenos
            Aires y a 50 kms de la misma.
          </StyledParagraph>
          <StyledParagraph
            spacing="1px"
            color="var(--orange)"
            size="16px"
            family="Manrope-bold"
            textAlign="center"
          >
            *Todo el personal diplomático argentino afiliado a APSEN tendrá una
            bonificación del seguro para su mudanza valuada hasta u$d 10.000 y
            de un almacenaje sin cargo por 30 días en los almacenes de la
            empresa.
          </StyledParagraph>
          <StyledTitle spacing="0.5px" color="var(--marineBlue)" size="26px">
            Para más información solicite un
          </StyledTitle>
          <PresupuestoButton to={url}>Presupuesto online</PresupuestoButton>
          <PresupuestoImage bImage="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325061/Banners/pexels-karolina-grabowska-4506267_gzofg7.jpg" />
        </PresupuestoContainer>
      )}
      {(id === "particulares-locales" || id === "particulares-internacionales") && (
        <PresupuestoContainer>
          <StyledTitle spacing="0.5px" color="var(--marineBlue)" size="26px">
            Bienvenido al{" "}
            <TextSpan color="var(--orange)" weight="800">
              {" "}
              servicio para particulares{" "}
            </TextSpan>{" "}
            de Elishar
          </StyledTitle>
          <StyledParagraph
            spacing="1px"
            color="var(--marineBlue)"
            size="20px"
            family="Manrope-light"
            textAlign="center"
          >
            Sabemos que la realización de una mudanza puede ser un proceso
            demandante, es por eso que con nuestro servicio de relocation, lo
            que asistiremos en todo lo que necesite para que su única
            preocupación sea qué cosas llevarse.
          </StyledParagraph>
          <StyledTitle spacing="0.5px" color="var(--marineBlue)" size="26px">
            Para más información solicite un
          </StyledTitle>
          <PresupuestoButton to={url}>Presupuesto online</PresupuestoButton>
          <PresupuestoImage bImage="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325041/Banners/pexels-ketut-subiyanto-4246196_sbw6rx.jpg" />
        </PresupuestoContainer>
      )}
    </>
  );
};
