import { createSlice } from "@reduxjs/toolkit";
import { exactVolumes } from "../../data";

const volumeSlice = createSlice({
  name: "steper",
  initialState: {
    exactVolumes,
  },
  reducers: {
    increment: (state, idHome) => {
      // const estado = JSON.parse(JSON.stringify(state)).exactVolumes;
    },
    decrement: (state, idHome, id) => {
      // const estado = JSON.parse(JSON.stringify(state)).exactVolumes;
    },
  },
});

export const volumeReducer = volumeSlice.reducer;

export const { increment, decrement } = volumeSlice.actions;