import { useController } from "react-hook-form";
import styled from "styled-components";

const FormNameDiv = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  flex-direction: ${ props => props.flexDirection || 'column' };
  align-items: flex-start;
  gap: 5px;
  position: relative;
  &:focus-within label {
    transform: ${ props => props.noTransform ? '' : 'translate(0, -23px) scale(0.6)' };
  }
  label {
    transform: ${ props => props.transform ? 'translate(0, -23px) scale(0.6)' : '' };
  }
  

  @media (max-width: 1000px) {
    width: ${(props) => props.widthMil};
  }
  @media (max-width: 680px) {
    width: ${(props) => props.widthR || "90%"};
  }
  @media (max-width: 550px) {
    width: 90%;
  }
`;

const FormNameInput = styled.input`
  width: ${ props => props.widthInput || '100%' };
  height: ${ props => props.height || '50px' };
  border: ${ props => props.border || '1.5px solid var(--pearlGrey)' };
  font-family: "Manrope-regular";
  font-size: ${ props => props.fontSize || '1.3rem' };
  padding-left: ${ props => props.paddingLeft || '25px' };
  border-radius: ${ props => props.borderRadius };
  text-overflow: ellipsis;

  &::placeholder {
    font-family: ${ props => props.placeholderFont || 'Manrope-medium' };
    color: ${ props => props.placeholderColor || 'var(--pearlGrey)' };
    height: 100%;
  }
  &:focus {
    border: none;
    outline: ${ props => props.outline || '1px solid var(--marineBlue)' };
  }
`;

const FormNameTextArea = styled.textarea`
  width: 100%;
  height: ${ props => props.height || '120px' };
  border: ${ props => props.border || '1.5px solid var(--pearlGrey)'};
  border-radius: ${ props => props.borderRadius || '1.5px solid var(--pearlGrey)'};
  resize: none;
  font-family: "Manrope-regular";
  font-size: 1.3rem;
  padding: 15px 0px 0px 25px;

  &::placeholder {
    font-family: 'Manrope-regular';
    color: var(--pearlGrey);
  }
  &:focus {
    outline: ${ props => props.outline || '1px solid var(--marineBlue)' };
    transition: all 0.3s ease-in-out;
  }
`;

const FormNameLabel = styled.label`
  font-family: "Manrope-medium";
  font-size: 19px;
  color: #999999ce;
  text-align: left;
  padding-left: ${ props => props.paddingLeft || '40px' };
  position: ${ props => props.position || 'absolute' };
  left: -12px;
  top: ${ error => error ? '25px' : '0' };
  pointer-events: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform-origin: top left;
  transform: ${ props => props.transform };
  @media (max-width: 890px) {
    font-size: 15px;
    padding-left: ${ props => props.paddingLeftR };
    top: ${ error => error ? '28px' : '0' };
  }
  @media (max-width: 550px) {
    width: ${ props => props.labelWidth || 'auto' };
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 0.8rem;
  font-family: "Manrope-regular";
`;

export const Input = ({
  id,
  width,
  type,
  placeholder,
  label,
  textArea,
  error,
  control,
  name,
  rules,
  height,
  border,
  fontSize,
  paddingLeft,
  borderRadius,
  outline,
  placeholderColor,
  placeholderFont,
  flexDirection,
  position,
  transform,
  noTransform,
  widthMil,
  widthR,
  labelWidth,
  min,
  paddingLeftR,
  widthInput,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: "",
  });

  const selectedInput = textArea ? (
    <FormNameTextArea
      {...field}
      id={id}
      placeholder={placeholder}
      name={name}
      label={label}
      height={height}
      border={border}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      borderRadius={borderRadius}
      outline={outline}
      placeholderColor={placeholderColor}
      placeholderFont={placeholderFont}
    />
  ) : (
    <FormNameInput
      {...field}
      type={type}
      id={id}
      placeholder={placeholder}
      name={name}
      label={label}
      height={height}
      border={border}
      min={min}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      borderRadius={borderRadius}
      outline={outline}
      placeholderColor={placeholderColor}
      placeholderFont={placeholderFont}
      widthInput={widthInput}
      autoComplete="off"
    />
  );

  return (
    <FormNameDiv widthMil={widthMil} width={width} widthR={widthR} flexDirection={flexDirection} noTransform={noTransform}>
      <FormNameLabel paddingLeftR={paddingLeftR} labelWidth={labelWidth} transform={transform} position={position} paddingLeft={paddingLeft} htmlFor={id}>{label}</FormNameLabel>
      {selectedInput}
      {error && <ErrorMsg>{error.message}</ErrorMsg>}
    </FormNameDiv>
  );
};