import React from "react";
import { Paragraph, Secondarytitle, Title, Image } from "../../components";
import {
  AlternateInfoContainer,
  SectionTitleContainer,
  SingleSegment,
} from "../alliances/Alliances";
import styled from "styled-components";

const MaterialsContainer = styled.div`
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  gap: 50px;
  margin: 0 auto;
`;

export const MaterialTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  //padding as a prop
  padding: ${(props) => props.padding};
  max-width: 400px;
`;

export const MaterialSegment = styled(SingleSegment)`
  justify-content: space-around;
  padding: 10px 0px 50px 0px;
  width: 100%;
`;

export const Materials = () => {
  return (
    <MaterialsContainer>
      <AlternateInfoContainer>
        <SectionTitleContainer>
          <Title
            color="var(--marineBlue)"
            family="Manrope-bold"
            size="30px"
            padding="0px 0px 10px 0px"
          >
            Material de Embalaje
          </Title>
        </SectionTitleContainer>
        <MaterialSegment>
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Cinta de embalar
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Utilizada para cerrar cajas y asegurar embalajes.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325130/Embalaje/Cinta_de_Embalar_nexqkk.jpg"
            alt="Cinta de embalar"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment direction="row-reverse">
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Film
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Este material lo utilizamos para proteger los efectos del polvo y
              para asegurar los materiales que los protegen de los daños.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325131/Embalaje/set-pallet-stretch-wrapping-film-roll-box-packaging-package_181654-4137.jpg_lammsa.webp"
            alt="Film"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment>
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Polietileno Pluribol (Film burbuja)
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Este material, es conocido comúnmente como film burbuja, lo
              utilizamos para acolchar objetos delicados.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325130/Embalaje/Globito_kicjki.jpg"
            alt="Polietileno Pluribol"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment direction="row-reverse">
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Cajas de diversos tamaños
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Nuestras cajas están hechas de un duro y resistente cartón y las
              usamos para transportar adornos, vajilla, platería y todo tipo de
              pequeñas cosas delicadas.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325130/Embalaje/cardboard-box-pile-blank-closed-parcels-realistic-style-vector-illustration_533410-949.jpg_yj6ltf.webp"
            alt="Cajas de diversos tamaños"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment>
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Rollos de cartón
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Estos rollos se utilizan para proteger a los muebles de ser
              dañados.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325133/Embalaje/Rollos_de_carton_hlbeuu.png"
            alt="Rollos de carbón"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment direction="row-reverse">
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Cajones de madera especializados y a medida
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Los cajones de madera son un importante material para transportar
              muebles delicados, pianos acústicos y otros efectos que necesiten
              una protección especial.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325130/Embalaje/shipping-wooden-box-500x500.jpg_qfhlp7.webp"
            alt="Cajones de madera especializados y a medida"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment>
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Papel de Seda
            </Secondarytitle>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325131/Embalaje/Papel_cebolla_yq7722.jpg"
            alt="Papel de seda"
            height="200px"
          />
        </MaterialSegment>
        <MaterialSegment direction="row-reverse">
          <MaterialTextContainer>
            <Secondarytitle
              color="var(--marineBlue)"
              family="Manrope-bold"
              size="18px"
            >
              Espuma de polietileno
            </Secondarytitle>
            <Paragraph
              color="var(--marineBlue)"
              size="14px"
              family="Manrope-regular"
              textAlign="left"
              lineHeight="1.5"
            >
              Este material, similar al film burbuja, es utilizado para envolver
              los muebles y acolcharlos, y así protegerlos de daños.
            </Paragraph>
          </MaterialTextContainer>

          <Image
            src="https://res.cloudinary.com/dvxeh973m/image/upload/v1672325132/Embalaje/Espuma_de_polietileno_sdxtl2.png"
            alt="Espuma de polietileno"
            height="200px"
          />
        </MaterialSegment>
      </AlternateInfoContainer>
    </MaterialsContainer>
  );
};
