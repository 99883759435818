import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
`;

export const ConfirmDialog = (props) => {
  const { title, message, onConfirm, onCancel } = props;

  return (
    <Overlay>
      <DialogContainer>
        <Title>{title}</Title>
        <p>{message}</p>
        <Button onClick={onConfirm}>Aceptar</Button>
        <Button onClick={onCancel}>Seguir seleccionando</Button>
      </DialogContainer>
    </Overlay>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  // onConfirm: PropTypes.func.isRequired,
  // onCancel: PropTypes.func.isRequired,
};


