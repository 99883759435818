import React from 'react'
import { useSelector } from 'react-redux';

export const Results = () => {

  const { contact, volumes, details, volumesCalculator, ...root } = useSelector(state => state.root);

  const html =  `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8">
      <title>Información Diplomática</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 14px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
        }
        .row {
          display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
        }
        .column {
          flex: 1;
          padding: 10px;
          width: 100%;
        }
        .card {
          border: 1px solid #ddd;
          border-radius: 5px;
          width: 90%;
          box-shadow: 0 0 5px #ddd;
          padding: 10px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 768px) {
          .column {
            flex-basis: 100%;
          }
        }
      </style>
    </head>
    <body>
      <div class="container">
        <h1>Información ${root.id.id}</h1>
        <table>
          <tr>
            <th>ID</th>
            <td>${root.id.leadId}</td>
          </tr>
          <tr>
            <th>Nombre de Contacto</th>
            <td>${contact.name}</td>
          </tr>
          <tr>
            <th>Correo Electrónico</th>
            <td>${contact.email}</td>
          </tr>
          <tr>
            <th>Teléfono</th>
            <td>${contact.phone}</td>
          </tr>
          <tr>
            <th>País de Origen</th>
            <td>${details.from.country}</td>
          </tr>
          <tr>
            <th>Ciudad de Origen</th>
            <td>${details.from.city}</td>
          </tr>
          <tr>
            <th>Dirección de Origen</th>
            <td>${details.from.address}, ${details.from.zipCode}</td>
          </tr>
          <tr>
            <th>País de Destino</th>
            <td>${details.to.country}</td>
          </tr>
          <tr>
            <th>Ciudad de Destino</th>
            <td>${details.to.city}</td>
          </tr>
          <tr>
            <th>Dirección de Destino</th>
            <td>${details.to.address}, ${details.to.zipCode}</td>
          </tr>
          <tr>
            <th>Fecha de Envío</th>
            <td>${details.date}</td>
          </tr>
          <tr>
            <th>Volumen de Transporte</th>
            <td>${volumes.transportTypes}, ${volumes.description}</td>
          </tr>
          ${volumesCalculator ?  `<tr>
            <th>Volumen de Transporte</th>
            <td> ${volumesCalculator.truckValues.currentVolume.toFixed(2) } m3 (recordá que el volumen de tu mudanza puede variar).</td>
          </tr>` : ''}
        </table>
        ${volumesCalculator ? '<h2>Detalle de Volumen</h2>' : ''}
        <div class="row">
          ${volumesCalculator ? volumesCalculator.volumes.map(volume => `
            <div class="column">
              <div class="card">
                <h3>${volume.name}</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Unidad</th>
                      <th>Volumen (m3)</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${volume.data.map(data => `
                      <tr>
                        <td>${data.name}</td>
                        <td>${data.quantity}</td>
                        <td>${data.unit}</td>
                        <td>${data.volume}</td>
                      </tr>
                    `).join('')}
                  </tbody>
                </table>
              </div>
            </div>
          `).join('') : ''}
        </div>
      </div>
    </Body>
  </html>
        ` ;

  return (
    <>
      <div dangerouslySetInnerHTML={{__html: html}}></div>
    </>
  )
}
