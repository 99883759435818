import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { mediaQueries, schema } from '../../../config'
import { Input } from '../../../components'

const ContactFormContainer = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 770px) {
    width: 80%;
    padding-bottom: 10%;
  }

  ${mediaQueries.mobile}{
    width: 100%;
    padding: 60px 0px;
    padding-bottom: 100px;
  }
`

const ContactFormTitlesContainer = styled.div`
  width: 60%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px
  ${mediaQueries.mobile}{
    width: 80%;
  }
  @media (max-width: 492px) {
    width: 90%;
  }
`

const ContactFormTitle = styled.h2`
  width: 100%;
  font-family: 'Manrope-regular';
  font-size: 1.5rem;
  color: var(--marineBlue);
  ${mediaQueries.mobile}{
    text-align: center;
  }
`

const ContactFormSubtitle = styled.h3`
  width: 100%;
  font-family: 'Manrope-light';
  font-size: 1rem;
  color: var(--marineBlue);
  ${mediaQueries.mobile}{
    text-align: center;
  }
`

const Form = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-top: 20px;
  gap: 20px;

  ${mediaQueries.mobile}{
    width: 80%;
    align-items: center;
  }
  @media (max-width: 492px) {
    width: 90%;
  }
`;

const FormInputSubmit = styled.input`
  width: 30%;
  background-color: var(--marineBlue);
  color: var(--offWhite);
  height: 35px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: white;
    color: var(--marineBlue);
    border: solid 1px var(--marineBlue);
  }
  
  ${mediaQueries.mobile}{
    width: 40%;
  }
`;

const FormSuccess = styled.p`
  color: green;
  font-size: 1rem;
  font-family: "Manrope-regular";
`;

const FormError = styled.p`
  color: var(--orange);
  font-size: 1rem;
  font-family: "Manrope-regular";
`;

export const ContactForm = () => {
  const [isValidForm, setIsValidForm] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = async(data) => {
    const { name, email, msg, phone } = data;
    const templateParams = {
      name,
      email,
      phone,
      msg,
    };
    try {
      const response = await fetch(
      'https://us-central1-elisharweb-d39e7.cloudfunctions.net/contactForm',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...templateParams, secret: 'firebaseIsCool'}),
      });
      console.log(response);
      if(response.status === 200) {
        setIsValidForm(true);
      }
      reset();
    } catch (error) {
      console.log(error);
      setIsValidForm(false);
    }
  };
  useEffect(() => {
    let interval;
    interval = setTimeout(() => {
      setIsValidForm(null);
    }, 6000);
      return () => {
      clearInterval(interval);
    };
  }, [isValidForm]);


  return (
    <ContactFormContainer>
      <ContactFormTitlesContainer>
        <ContactFormTitle>Hacenos llegar tu consulta</ContactFormTitle>
        <ContactFormSubtitle>Completa el siguiente formulario y nos pondremos en contacto.</ContactFormSubtitle>
      </ContactFormTitlesContainer>
      <Form
          autocomplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          name="contact"
        >
          <Input
            id="name"
            name="name"
            width="100%"
            type="text"
            placeholder="Nombre"
            label=""
            control={control}
            error={errors.name}
          />
          <Input
            id="email"
            name="email"
            width="100%"
            type="email"
            placeholder="Email"
            label=""
            control={control}
            error={errors.email}
          />
          <Input
            id="phone"
            name="phone"
            width="100%"
            type="text"
            placeholder="Teléfono"
            label=""
            control={control}
            error={errors.phone}
          />
          <Input
            textArea
            id="msg"
            name="msg"
            width="100%"
            type="text"
            placeholder="Consulta"
            label=""
            control={control}
            error={errors.msg}
          />
          {isValidForm ? (
            <FormSuccess>El mensaje se ha enviado correctamente</FormSuccess>
          ) : isValidForm === false ? (
            <FormError>Error de servidor, intente mas tarde.</FormError>
          ) : null}
          <FormInputSubmit type="submit" value="ENVIAR" />
        </Form>
    </ContactFormContainer>
  )
}
