import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
//importar react icons
import { FaAngleRight } from "react-icons/fa";

const StyledNavbar = styled.nav`
  height: 70px;
  background-color: var(--grafGrey);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;

  @media (max-width: 992px) {
    display: none;
  }
`;

const NavbarList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  width: 100%;
  list-style: none;
  padding: 0 7%;
`;

const NavbarListItem = styled.li`
  text-transform: uppercase;
  color: var(--offWhite);
  font-family: "Manrope-semiBold";
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
  user-select: none;

  // rotate arrow when mouse is over
  svg {
    transform: ${(props) =>
      props.mouseOver ? "rotate(90deg)" : "rotate(0deg)"};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    ul {
      animation: fadeIn 0.3s ease-in-out forwards;
    }
  }
`;

const NavbarButton = styled(Link)`
  background-color: var(--orange);
  color: var(--offWhite);
  padding: 10px 50px;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Manrope-bold";
  cursor: pointer;
  border: 1px solid var(--orange);
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: var(--orange);
    transition: all 0.3s ease-in-out;
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  left: -20px;
  width: ${(props) => props.width};
  top: 15px;
  background-color: var(--grafGrey);
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 15px 15px 15px;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  display: ${(props) => (props.mouseUp ? "flex" : "none")};
  opacity: 0;
`;

const DropdownItem = styled.li`
  color: var(--offWhite);
  font-family: "Manrope-semiBold";
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 10px 30px 10px 8px;
  transition: all 0.3s ease-in-out;
`;

const DropdownItemLink = styled(NavLink)`
  color: var(--offWhite);
  font-family: "Manrope-semiBold";
  cursor: pointer;
  font-size: 13px;
  width: 100%;
  height: 100%;
`;

const StyledNavLink = styled(NavLink)`
  color: var(--offWhite);
`;

const conocenosOptions = [
  { name: "Compañia", path: "/la-compania" },
  { name: "Alianzas", path: "/alianzas" },
  { name: "Materiales", path: "/materiales" },
  { name: "Contacto", path: "/contacto" },
  { name: "FAQ", path: "/preguntas-frecuentes" },
];
const requisitosOptions = [
  { name: "Documentación", path: "/requisitos" },
];

const embajadasOptions = [
  { name: "Diplomáticos", path: "/presupuesto/diplomaticos" },
];

const particularesOptions = [
  { name: "Locales", path: "/presupuesto/particulares-locales" }, // TODO: si es local y accede al presupuesto, que se setee por defecto argentina en el país
  { name: "Internacionales", path: "/presupuesto/particulares-internacionales" },
];

export const Navbar = () => {
  const [embajadasMouseUp, setembajadasMouseUp] = useState(false);
  const [particularesMouseUp, setparticularesMouseUp] = useState(false);
  const [conocenosMouseUp, setconocenosMouseUp] = useState(false);
  const [requisitosMouseUp, setrequisitosMouseUp] = useState(false);
  return (
    <StyledNavbar>
      <NavbarList>
        <NavbarListItem
          onMouseOver={() => setembajadasMouseUp(true)}
          onMouseOut={() => setembajadasMouseUp(false)}
          mouseOver={embajadasMouseUp}
        >
          <StyledNavLink to="/">Servicios para Embajadas</StyledNavLink>
          <FaAngleRight />
          <Dropdown width=" calc(100% + 60px)" mouseUp={embajadasMouseUp}>
            {embajadasOptions.map((option, index) => (
              <DropdownItem key={index}>
                <DropdownItemLink to={option.path}>
                  {" "}
                  {option.name}{" "}
                </DropdownItemLink>
              </DropdownItem>
            ))}
          </Dropdown>
        </NavbarListItem>
        <NavbarListItem
          onMouseOver={() => setparticularesMouseUp(true)}
          onMouseOut={() => setparticularesMouseUp(false)}
          mouseOver={particularesMouseUp}
        >
          <StyledNavLink to="/">Servicios para particulares</StyledNavLink>
          <FaAngleRight />
          <Dropdown width=" calc(100% + 60px)" mouseUp={particularesMouseUp}>
            {particularesOptions.map((option, index) => (
              <DropdownItem key={index * 10}>
                <DropdownItemLink to={option.path}>
                  {" "}
                  {option.name}{" "}
                </DropdownItemLink>
              </DropdownItem>
            ))}
          </Dropdown>
        </NavbarListItem>
        <NavbarListItem
          onMouseOver={() => setconocenosMouseUp(true)}
          onMouseOut={() => setconocenosMouseUp(false)}
          mouseOver={conocenosMouseUp}
        >
          Conocenos
          <FaAngleRight />
          <Dropdown width=" calc(100% + 100px)" mouseUp={conocenosMouseUp}>
            {conocenosOptions.map((option, index) => (
              <DropdownItem key={index * 100}>
                <DropdownItemLink to={option.path}>
                  {" "}
                  {option.name}{" "}
                </DropdownItemLink>
              </DropdownItem>
            ))}
          </Dropdown>
        </NavbarListItem>
        <NavbarListItem
          onMouseOver={() => setrequisitosMouseUp(true)}
          onMouseOut={() => setrequisitosMouseUp(false)}
          mouseOver={requisitosMouseUp}
        >
          Requisitos
          <FaAngleRight />
          <Dropdown width=" calc(100% + 100px)" mouseUp={requisitosMouseUp}>
            {requisitosOptions.map((option, index) => (
              <DropdownItem key={index * 100}>
                <DropdownItemLink to={option.path}>
                  {" "}
                  {option.name}{" "}
                </DropdownItemLink>
              </DropdownItem>
            ))}
          </Dropdown>
        </NavbarListItem>
        <NavbarButton to="/presupuesto">Presupuesto Online</NavbarButton>
      </NavbarList>
    </StyledNavbar>
  );
};
