import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";

const Container = styled.a`
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: rgb(104,214,73);
  background: linear-gradient(0deg, rgba(104,214,73,1) 0%, rgba(29,157,16,1) 50%, rgba(29,157,16,1) 100%);
  border-radius: 16px;
  z-index: 10;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  display: flex;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 40px;
  @media (max-width: 576px) {
    right: 15px;
    bottom: 20px;
  }
`;
const mensaje = `Hola`;

const WhatsappBTN = () => {
  return (
    <Container
      href={`https://wa.me/541135847805?text=${mensaje}`}
      target={"_blank"}
    >
      <FontAwesomeIcon icon={faWhatsapp} color={"white"} />
    </Container>
  );
};

export default WhatsappBTN;
