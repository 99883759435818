import React from "react";
import styled from "styled-components";
import { Title, Paragraph, Image, TextSpan } from "../../components";
import { AlliancesContainer } from "../../components";

const CompanyContainer = styled.div`
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  gap: 60px;
  margin: 0 auto;
`;

export const InfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  width: min(90%, 700px);
  gap: 30px;
`;
export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Company = () => {
  return (
    <CompanyContainer>
      <InfoContainer>
        <Title color="var(--marineBlue)" family="Manrope-bold" size="30px">
          La Compañia
        </Title>
        <ParagraphContainer>
          <Paragraph
            color="var(--marineBlue)"
            size="15px"
            family="Manrope-regular"
            textAlign="left"
            lineHeight="1.5"
          >
            Elishar Mudanzas Internacionales se estableció en Buenos Aires,
            Argentina, en el año 2002. Acumulando desde entonces una vasta
            experiencia en el rubro. Contamos con una enorme red de agentes en
            todo el mundo y hasta en las ciudades más remotas. A nuestros
            agentes los conocemos de manera{" "}
            <TextSpan color="--marineBlue" weight="800">
              personal
            </TextSpan>{" "}
            ya que asistimos anualmente a no menos de tres convenciones
            internacionales que se desarrollan en distintas partes del mundo
            donde somos miembros activos.
          </Paragraph>
          <Paragraph
            color="var(--marineBlue)"
            size="15px"
            family="Manrope-regular"
            textAlign="left"
            lineHeight="1.5"
          >
            {" "}
            De esta forma elegimos en cada país un agente responsable quien es
            el que definitivamente complementará nuestro trabajo. Estos agentes
            deberán ser miembros de alguna de las siguientes asociaciones de las
            que somos orgullosos{" "}
            <TextSpan color="--marineBlue" weight="800">
              miembros activos
            </TextSpan>
            ; pertenecer a estas asociaciones nos asegura a nosotros y a
            nuestros clientes que estamos trabajando con empresas confiables,
            las cuales comparten con nosotros códigos de ética de los cuales
            todos los miembros estamos comprometidos.
          </Paragraph>
        </ParagraphContainer>
      </InfoContainer>
      <AlliancesContainer width="min(90%, 700px);">
        <Image
          src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222092/Alianzas/ARA_qve0rl-removebg-preview_jtllan.png"
          alt="Asian Relocation Association"
          height="70px"
          responsiveHeight="200px"
        />
        <Image
          src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222093/Alianzas/IAM_ar3vig-removebg-preview_fybzap.png"
          alt="International Association of movers"
          height="60px"
          responsiveHeight="130px"
        />
        <Image
          src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673222094/Alianzas/IMA_nx07yb-removebg-preview_pnheoj.png"
          alt="International Mobility Alliance"
          height="70px"
          responsiveHeight="145px"
        />
        <Image
          src="https://res.cloudinary.com/dvxeh973m/image/upload/v1673221432/Alianzas/MoversPOE_ivn9nk_qrgevd.png"
          alt="Movers POE"
          height="70px"
          responsiveHeight="200px"
        />
        <Image
          src="https://res.cloudinary.com/dvxeh973m/image/upload/v1680707873/Alianzas/Logo_Eurovan_gllavp.png"
          alt="Eurovan"
          height="70px"
          responsiveHeight="200px"
        />
      </AlliancesContainer>
    </CompanyContainer>
  );
};
